import { getUserToken } from '../components/firebase/firebase';
import Request from './Request';

const { PostSecuredData } = Request();

export const getStripeProducts = async (email) => {
  const token = await getUserToken();
  const payload = { email };
  const customer = await PostSecuredData('mixer/get-products', payload, token);
  return customer;
};
