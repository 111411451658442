import { ORMURL } from '../constants/Url';

const Request = () => {
  const { Prod, baseUrl } = ORMURL();

  const GetSecuredData = async (url: string, token: string) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      }
    });
    const data = await response.json();
    return data;
  };

  const GetData = async (url: string) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });
    const data = await response.json();
    return data;
  };

  const PostSecuredData = async (url: string, payload: Record<string, unknown>, token: string) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const PostData = async (url: string, payload: Record<string, unknown>) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const PutData = async (url: string, payload: Record<string, unknown>, token?: string) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    const response = await fetch(`${Prod}${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const PostDataCustomUrl = async (customUrl: string, payload: Record<string, unknown>, token?: string) => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    let body = {};
    if (payload) {
      body = JSON.stringify(payload);
    }
    const response = await fetch(`${customUrl}`, { method: 'POST', headers, body });
    const data = await response.json();
    return data;
  };

  const PostAiData = async (url: string, payload: Record<string, unknown>) => {
    const aiBaseUrl = baseUrl.includes('localhost') ? 'https://app.mixl.ai' : baseUrl;
    const response = await fetch(`${aiBaseUrl}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  return {
    PostData,
    PutData,
    GetSecuredData,
    PostSecuredData,
    PostDataCustomUrl,
    PostAiData,
    GetData
  };
};

export default Request;
