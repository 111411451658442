import { aiAgentUrl } from '../constants/Url';
import Request from './Request';

const { PostDataCustomUrl } = Request();

export const getAgentResponse = async (
  message,
  history,
  bot_action = '',
  isUserInSession,
  isReviewActive = false,
  question
) => {
  if (isReviewActive && question) {
    const data = await PostDataCustomUrl(`${aiAgentUrl}/live_help`, {
      message,
      history,
      question
    });

    return data;
  }

  if (bot_action === 'createOutline') {
    const data = await PostDataCustomUrl(`${aiAgentUrl}/coach`, { message, history, bot_action });

    return data;
  }
  const response = await PostDataCustomUrl(`${aiAgentUrl}/coach`, {
    message,
    history,
    bot_action,
    isUserInSession: Boolean(isUserInSession)
  });

  return response;
};
