import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toLower } from 'lodash';
import { v4 as generateId } from 'uuid';

import { firebaseAuth, firebaseDB, firebaseStorage } from '../../components/firebase/firebase';
import { aiAgentUrl } from '../../constants/Url';
import Request from '../../Services/Request';
import { RootState } from '../root-reducer';
import { INoteData, IQuizData, IStudyData } from './types';

const { PostDataCustomUrl } = Request();

export async function saveStudySessionData(studyData: IStudyData, sessionId: string) {
  const { quizData, studyNotes } = studyData;
  if (!sessionId || !studyNotes.length) {
    return;
  }
 
  const payload = { quizData, studyNotes, sessionDate: Date.now() };
  const currentUser = firebaseAuth.currentUser;
  const userId = currentUser?.uid;
  const studyRef = collection(firebaseDB, 'glo-users', userId, 'study_sessions');
  if (sessionId) {
    payload['sessionId'] = sessionId;
  }

  const category = Array.from(new Set(studyNotes.map((note) => toLower(note?.quizTopic ?? 'other'))));
  payload['category'] = category;
  const docRef = doc(studyRef, sessionId);
  await setDoc(docRef, payload);
  studyNotes.map((note) => {
    const { urlLink, quizTopic = 'other' } = note;
    PostDataCustomUrl(`${aiAgentUrl}/upload_rag_file`, { fileUrl: urlLink, userId, quizTopic });
  });
}

export async function archiveSession(sessionId: string) {
  if (!sessionId) {
    console.error('No sessionId provided. Unable to archive study session.');
    return;
  }

  try {
    const currentUser = firebaseAuth.currentUser;
    const userId = currentUser?.uid;

    if (!userId) {
      console.error('User not authenticated. Cannot archive study session.');
      return;
    }

    const studyRef = collection(firebaseDB, 'glo-users', userId, 'study_sessions');
    const docRef = doc(studyRef, sessionId);
    const sessionSnapshot = await getDoc(docRef);

    if (!sessionSnapshot.exists()) {
      console.error(`Study session with ID ${sessionId} not found.`);
      return;
    }

    // Add `archived: true` to the session data
    await updateDoc(docRef, { archived: true });

    console.log(`Study session ${sessionId} successfully marked as archived.`);
  } catch (error) {
    console.error('Error archiving study session:', error);
  }
}

export async function uploadStudyNote(file: File, sessionId?: string) {
  const uid = firebaseAuth.currentUser?.uid;
  if (!uid) {
    throw new Error('invalid-user');
  }
  const namePrefix = sessionId ? `${sessionId}_` : '';
  const filePath = `/study_session/${uid}/${namePrefix}${file.name}`;
  const storageRef = ref(firebaseStorage, filePath);
  const snapshot = await uploadBytes(storageRef, file);
  const downloadRef = snapshot.ref;
  const storageUri = downloadRef.toString();
  const urlLink = await getDownloadURL(downloadRef);
  return { urlLink, storageUri };
}

export async function generateQuizQuestions(
  noteData: INoteData,
  isExperimental: boolean
): Promise<{ quizData: IQuizData[]; quizTopic: string }> {
  const { urlLink, noteId, max_results = 10 } = noteData;
  if (import.meta.env.DEVs) {
    const { mockQuizData } = await import('../../constants/mockData');
    const quizData = [...mockQuizData].map((item) => {
      if (!item) {
        return null;
      }
      item['noteId'] = noteId;
      item['id'] = generateId();
      item['selectedAnswer'] = '';
      return item;
    });
    return { quizData, quizTopic: 'mockTopic' };
  }
  try {
    const quizUrl = !isExperimental ? `${aiAgentUrl}/generate_study_quiz` : `${aiAgentUrl}/generate_training_quiz`;
    const payload = {
      file_url: urlLink,
      max_results
    };
    const uid = firebaseAuth.currentUser?.uid;
    if (!uid) {
      throw new Error('invalid-user');
    }
    payload['uid'] = uid;
    const { quiz, category } = await PostDataCustomUrl(`${quizUrl}`, payload);
    if (!quiz || !quiz?.length > 0) {
      throw new Error('no-quiz-found');
    }
    const quizData: IQuizData[] = quiz
      .map((item) => {
        if (!item) {
          return null;
        }
        item['noteId'] = noteId;
        item['id'] = generateId();
        item['selectedAnswer'] = '';
        return item;
      })
      .filter((item) => item !== null && item !== undefined);

    const quizTopic = toLower(category);

    return { quizData, quizTopic };
  } catch (error) {
    console.log('Err: unable to process link ', noteData, error.message);
    return [];
  }
}

export function getStudyRoomName(getState: () => RootState) {
  const store = getState();
  const roomName = store.studyData.roomInfo?.roomName;
  return roomName;
}
export function getStudyNotesLinks(getState: () => RootState) {
  const store = getState();
  const studyNotes = store.studyData.studyNotes;
  return studyNotes;
}

export function convertToGsUrl(studyNote: INoteData) {
  try {
    const storageUri = studyNote?.storageUri;
    if (storageUri) {
      return storageUri;
    }
    const firebaseUrl = studyNote?.urlLink;
    // Extract the bucket and path components from the URL
    const url = new URL(firebaseUrl);
    const path = url.pathname
      .replace(/^\/v0\/b\//, '') // Remove "/v0/b/"
      .replace('/o/', '/'); // Replace "/o/" with "/"

    // Decode URL-encoded characters like "%2F" to "/"
    const decodedPath = decodeURIComponent(path);

    // Construct the gs:// URL
    return `gs://${decodedPath}`;
  } catch (error) {
    console.error('Invalid Firebase URL:', error);
    return null;
  }
}
