import { collection, getDocs, query, where } from 'firebase/firestore';
import { toLower } from 'lodash';
import { DateTime } from 'luxon';

import { firebaseAuth, firebaseDB, getUserToken } from '../../components/firebase/firebase';
import { whiteListedDomain } from '../../constants/theme';
import { getStripeProducts } from '../../Services/PaymentService';
import Request from '../../Services/Request';
import { daysOfWeek, formatSecondsToHoursMinutes } from '../../utils/dateUtils';
import { MUSIC_STATIONS } from '../../utils/mixlFlow';
import { postIframeMessage } from '../../utils/platformUtils';
import { TEAM_BADGES } from '../../utils/teamUtils';
import { IMixlBadge, IMixlTask } from '../../utils/Types';

const { PostSecuredData } = Request();

export const lookupUserSubscription = async () => {
  const email = firebaseAuth.currentUser?.email;
  if (!email) {
    return false;
  }
  if (whiteListedDomain.some((domain) => email.includes(domain))) {
    return true;
  }

  const resp = await getStripeProducts(email);
  const currnetPlan = resp?.purchases || [];
  console.log('lookupUserSubscriptionDebug: ', currnetPlan, currnetPlan.length);
  if (!currnetPlan || !currnetPlan?.length) {
    // vikari sala check how many
    const sessionStarted = await getSessionStartedToday();
    if (sessionStarted) {
      console.log('lookupUserSubscriptionDebug: thats all for free user');
      return false;
    }
    return true;
  }
  const products = resp?.products || [];
  const activeProduct = products.find((product) => currnetPlan.includes(product?.id));
  console.log('lookupUserSubscriptionDebug: activeProduct ', activeProduct);
  if (!activeProduct) {
    return false;
  }
  const activTier = toLower(activeProduct.tier);
  if (activTier === 'free') {
    // check if did session today
    const sessionStarted = await getSessionStartedToday();
    if (sessionStarted) {
      console.log('lookupUserSubscriptionDebug: thats all for free user');
      return false;
    }
    console.log('lookupUserSubscriptionDebug: granting 1 session');
    return true;
  }
  return true;
};

export async function getSessionStartedToday() {
  const uid = firebaseAuth.currentUser?.uid;
  const sessionRef = collection(firebaseDB, 'glo-users', uid, 'flow_sessions');
  const now = DateTime.now();
  const lastday = now.minus({ hours: 24 }).toMillis();
  const queryData = query(sessionRef, where('timestamp', '>=', lastday));
  const snapshot = await getDocs(queryData);
  if (snapshot.docs.length > 0) {
    console.log('lookupUserSubscriptionDebug: thats all for free user', snapshot.docs.length);
    return true;
  }
  return false;
}

async function getSlackChannelList(slackToken: string) {
  if (!slackToken) {
    throw new Error('Invalid token');
  }
  const token = await getUserToken();
  const { slackChannels = [] } = await PostSecuredData('community/slack_channels', { slackToken }, `Bearer ${token}`);
  return [...slackChannels];
}

export const mapBadgesToUnlockedStations = async (badges: IMixlBadge[] = []) => {
  if (!badges.length) {
    return MUSIC_STATIONS;
  }

  const stations = await Promise.all(
    MUSIC_STATIONS.map((music) => {
      if (music.abrev === 'afrobeats' && badges?.find((e) => e.name === TEAM_BADGES.starter.name)) {
        music.isPremium = false;
      } else if (music.abrev === 'hip_hop' && badges?.find((e) => e.name === TEAM_BADGES.steady.name)) {
        music.isPremium = false;
      } else if (music.abrev === 'funk' && badges?.find((e) => e.name === TEAM_BADGES.team.name)) {
        music.isPremium = false;
      } else if (music.abrev === 'bossanova' && badges?.find((e) => e.name === TEAM_BADGES.flow.name)) {
        music.isPremium = false;
      } else if (music.abrev === 'trap' && badges?.find((e) => e.name === TEAM_BADGES.guru.name)) {
        music.isPremium = false;
      }

      return music;
    })
  );

  return stations;
};

export async function getUnlockedLockedStations() {
  const badges = await getBadgesUser();
  const newMusicList = await mapBadgesToUnlockedStations(badges);
  return newMusicList;
}

export function createMixlTaskObject(taskName: string, docId: string, timeNow: number): IMixlTask {
  return {
    details: '',
    status: {
      status: 'open'
    },
    taskName,
    name: taskName ?? '',
    isTodayTask: true,
    type: 'custom',
    tagName: '',
    priority: {
      color: '#6fddff',
      id: '3',
      orderindex: '3',
      priority: 'normal'
    },
    createdAt: timeNow,
    id: docId,
    startTimestamp: timeNow,
    hasAnimated: false
  };
}

//FixMe: this Notification should be triggered almost realtime
export async function handleNotificationsBadges() {
  const badges = await getBadgesUser();
  const findStarterBadge = badges?.find((e) => e.name === TEAM_BADGES.starter.name);
  const findSteadyBadge = badges?.find((e) => e.name === TEAM_BADGES.steady.name);
  const findTeamBadge = badges?.find((e) => e.name === TEAM_BADGES.team.name);
  const findFlowBadge = badges?.find((e) => e.name === TEAM_BADGES.flow.name);
  const findGuruBadge = badges?.find((e) => e.name === TEAM_BADGES.guru.name);

  if (findStarterBadge && !findStarterBadge.isNotified) {
    postIframeMessage({
      type: 'end_session',
      badge: TEAM_BADGES.starter.name,
      station: 'afrobeats'
    });
    await updateBadgePayload({
      id: findStarterBadge.id,
      name: findStarterBadge.name,
      createdAt: findStarterBadge.createdAt,
      index: findStarterBadge.index,
      isNotified: true,
      updatedAt: new Date()
    });
    // update badge data here isNotified: true
  }
  if (findSteadyBadge && !findSteadyBadge.isNotified) {
    postIframeMessage({
      type: 'end_session',
      badge: TEAM_BADGES.steady.name,
      station: 'hip hop'
    });
    await updateBadgePayload({
      id: findSteadyBadge.id,
      name: findSteadyBadge.name,
      createdAt: findSteadyBadge.createdAt,
      index: findSteadyBadge.index,
      isNotified: true,
      updatedAt: new Date()
    });
    // update badge data here isNotified: true
  }

  if (findTeamBadge && !findTeamBadge.isNotified) {
    postIframeMessage({
      type: 'end_session',
      badge: TEAM_BADGES.team.name,
      station: 'funk'
    });
    await updateBadgePayload({
      id: findTeamBadge.id,
      name: findTeamBadge.name,
      createdAt: findTeamBadge.createdAt,
      index: findTeamBadge.index,
      isNotified: true,
      updatedAt: new Date()
    });
  }

  if (findFlowBadge && !findFlowBadge.isNotified) {
    postIframeMessage({
      type: 'end_session',
      badge: TEAM_BADGES.flow.name,
      station: 'bossa nova'
    });
    await updateBadgePayload({
      id: findFlowBadge.id,
      name: findFlowBadge.name,
      createdAt: findFlowBadge.createdAt,
      index: findFlowBadge.index,
      isNotified: true,
      updatedAt: new Date()
    });
  }

  if (findGuruBadge && !findGuruBadge.isNotified) {
    postIframeMessage({
      type: 'end_session',
      badge: TEAM_BADGES.guru.name,
      station: 'trap'
    });
    await updateBadgePayload({
      id: findGuruBadge.id,
      name: findGuruBadge.name,
      createdAt: findGuruBadge.createdAt,
      index: findGuruBadge.index,
      isNotified: true,
      updatedAt: new Date()
    });
  }
  const newStations = await getUnlockedLockedStations();
  return newStations;
}

const updateBadgePayload = async (badge) => {
  try {
    const payload = {
      id: badge.id,
      name: badge.name,
      createdAt: badge.createdAt,
      index: badge.index,
      isNotified: badge.isNotified || false,
      updatedAt: badge.updatedAt || new Date()
    };
    const currentUser = firebaseAuth.currentUser;
    const token = await currentUser?.getIdToken();
    await PostSecuredData('authapi/updateUserBadgePayload', payload, token);
  } catch (error) {
    console.log('An error occurred while updatting user badge data');
  }
};

const getBadgesUser = async () => {
  const email = firebaseAuth.currentUser?.email;
  const moderatorRef = collection(firebaseDB, 'gloqal-moderators');
  const queryRef = query(moderatorRef, where('email', '==', email));
  const snapshot = await getDocs(queryRef);
  const data = snapshot?.docs?.[0]?.data();
  const badges = data?.badges;
  return badges;
};

export { getSlackChannelList };

//to move to date utils

const getWeekDays = () => {
  const monday = new Date(getDayOfWeekTimestamp(1));
  const tuesday = new Date(getDayOfWeekTimestamp(2));
  const wednesday = new Date(getDayOfWeekTimestamp(3));
  const thursday = new Date(getDayOfWeekTimestamp(4));
  const friday = new Date(getDayOfWeekTimestamp(5));
  const saturday = new Date(getDayOfWeekTimestamp(6));
  const sunday = new Date(getDayOfWeekTimestamp(7));

  return {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  };
};

// move to API ro refactor code and reduce ressources
export async function getAiSessionData() {
  const uid = firebaseAuth.currentUser?.uid;
  const collectionRef = collection(firebaseDB, 'glo-users', uid, 'flow_sessions');
  const refQuery = query(collectionRef, where('timestamp', '>=', getFirstTimeOfWeek()));
  const snapshot = await getDocs(refQuery);
  let totalWeeklyBlockedWebsites = 0;
  let totalSavedTimeByAi = 0;
  let handledMessages = [];
  let blockedSites = [];
  let totalCompletedSessions = 0;
  let level = 0;
  const timeCompletionSessions = [];
  let totalFocusTime = 0;

  const weeklySessionsTime = daysOfWeek.reduce((obj, day) => {
    obj[day] = 0;
    return obj;
  }, {});

  if (snapshot?.size) {
    //unlock second badge
    await Promise.all(
      snapshot?.docs?.map(async (doc) => {
        const data = doc.data();
        const calculateTimeCompleted = () => {
          let time = data?.timeCompleted > 0 ? data?.timeCompleted : 0;
          if (data?.timeCompleted > data?.sessionLength && data?.sessionLength > 0) {
            time = data?.sessionLength;
          }
          return time;
        };
        const blockedSitesCount = data?.countBlockedSites ?? 0;
        const slackMessages = data?.slackMessages ?? [];
        const blockedSitesSession = data?.blockedSites ?? [];

        totalWeeklyBlockedWebsites = totalWeeklyBlockedWebsites + blockedSitesCount;
        handledMessages = [...handledMessages, ...slackMessages];
        blockedSites = [...blockedSites, ...blockedSitesSession];

        const timeCompleted = calculateTimeCompleted();
        timeCompletionSessions.push(timeCompleted);

        if (data.timeCompleted > 0 && (data.goalCompletion > 0 || data.timeCompleted >= data.sessionLength)) {
          totalCompletedSessions = totalCompletedSessions + 1;
        }
        const dateFromTimestamp = new Date(data.timestamp);
        const weekDays = getWeekDays();
        // Check if the timestamp falls on each day
        const result = {
          monday: dateFromTimestamp >= weekDays.monday && dateFromTimestamp < weekDays.tuesday,
          tuesday: dateFromTimestamp >= weekDays.tuesday && dateFromTimestamp < weekDays.wednesday,
          wednesday: dateFromTimestamp >= weekDays.wednesday && dateFromTimestamp < weekDays.thursday,
          thursday: dateFromTimestamp >= weekDays.thursday && dateFromTimestamp < weekDays.friday,
          friday: dateFromTimestamp >= weekDays.friday && dateFromTimestamp < weekDays.saturday,
          saturday: dateFromTimestamp >= weekDays.saturday && dateFromTimestamp < weekDays.sunday,
          sunday:
            dateFromTimestamp >= weekDays.sunday &&
            dateFromTimestamp <= new Date(weekDays.sunday.setHours(23, 59, 59, 999))
        };
        if (result.monday) {
          weeklySessionsTime['monday'] = weeklySessionsTime['monday'] + timeCompleted;
        } else if (result.tuesday) {
          weeklySessionsTime['tuesday'] = weeklySessionsTime['tuesday'] + timeCompleted;
        } else if (result.wednesday) {
          weeklySessionsTime['wednesday'] = weeklySessionsTime['wednesday'] + timeCompleted;
        } else if (result.thursday) {
          weeklySessionsTime['thursday'] = weeklySessionsTime['thursday'] + timeCompleted;
        } else if (result.friday) {
          weeklySessionsTime['friday'] = weeklySessionsTime['friday'] + timeCompleted;
        } else if (result.saturday) {
          weeklySessionsTime['saturday'] = weeklySessionsTime['saturday'] + timeCompleted;
        } else if (result.sunday) {
          weeklySessionsTime['sunday'] = weeklySessionsTime['sunday'] + timeCompleted;
        }
      })
    );
  }
  let averageTime = [];
  for (const key in weeklySessionsTime) {
    totalSavedTimeByAi = totalSavedTimeByAi + weeklySessionsTime[key];
    if (weeklySessionsTime[key]) {
      averageTime.push(weeklySessionsTime[key]);
    }
  }

  totalFocusTime = Object.values(weeklySessionsTime).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  averageTime = averageTime.length > 0 ? Math.max(...averageTime) : 0;

  //add level from timecompleted
  level = getLevelFocusUser(timeCompletionSessions);
  const maxTimeFocus = timeCompletionSessions.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  totalSavedTimeByAi = totalSavedTimeByAi * 0.3;
  const currentDate = new Date();
  const previousTotalFocus = await getPreviousWeekFocus(uid, currentDate);

  const change = previousTotalFocus ? calculateFocusTimeChange(totalFocusTime, previousTotalFocus) : '';

  return {
    totalWeeklySlackMessages: handledMessages.length,
    totalWeeklyBlockedWebsites,
    totalSavedTimeByAi: totalSavedTimeByAi,
    averageTime: averageTime,
    weeklySessionsTime,
    handledMessages,
    blockedSites,
    totalCompletedSessions,
    level: level,
    pourcentageFocus: change,
    maxFocusTime: maxTimeFocus ? formatSecondsToHoursMinutes(maxTimeFocus) : '0m',
    totalFocusTime: totalFocusTime
  };
}

function getDayOfWeekTimestamp(dayOfWeek) {
  const today = new Date();
  const currentDay = today.getDay();
  let daysDifference;
  if (currentDay > dayOfWeek) daysDifference = (dayOfWeek - currentDay) % 7;
  else daysDifference = (dayOfWeek + 7 - currentDay) % 7;
  today.setDate(today.getDate() + daysDifference);
  today.setHours(0, 0, 0, 0);
  return today.getTime();
}

function getLevelFocusUser(timeCompletionSessions) {
  const sum = timeCompletionSessions.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  let TimeInMinutes = sum > 0 ? sum : 0;
  TimeInMinutes = TimeInMinutes ? TimeInMinutes / 60 : 0;
  if (TimeInMinutes < 10) return 0;
  else if (TimeInMinutes >= 10 && TimeInMinutes < 30) return 1;
  else if (TimeInMinutes >= 30 && TimeInMinutes < 50) return 2;
  else if (TimeInMinutes >= 50 && TimeInMinutes < 100) return 3;
  else {
    let level = 3;
    level = level + Math.floor(TimeInMinutes / 50);
    return level;
  }
}

export function getTimeSaved(sessionData: string[]) {
  const blockedSites = sessionData?.blockedSites;
  if (!blockedSites?.length) {
    return 0;
  }
  const time = blockedSites.length * 1;
  return time ?? 0;
}

const getPreviousWeekFocus = async (uid, selectedDate) => {
  const collectionRef = collection(firebaseDB, 'glo-users', uid, 'flow_sessions');
  const currentDate = selectedDate ?? new Date();
  const previousWeekDate = new Date(currentDate);
  previousWeekDate.setDate(currentDate.getDate() - 7);
  const firstTimeOfWeek = getFirstTimeOfWeek(previousWeekDate);
  const lastTimeOfWeek = getLastTimeOfWeek(previousWeekDate);
  console.log(firstTimeOfWeek, lastTimeOfWeek);
  const refQuery = query(
    collectionRef,
    where('timestamp', '>=', firstTimeOfWeek),
    where('timestamp', '<=', lastTimeOfWeek)
  );

  try {
    let totalFocusTime = 0;

    const weeklySessionsTime = daysOfWeek.reduce((obj, day) => {
      obj[day] = 0;
      return obj;
    }, {});
    const snapshot = await getDocs(refQuery);
    if (!snapshot?.size) {
      console.log('No matching documents.');
      return {
        totalFocusTime: totalFocusTime
      };
    }
    snapshot.docs.forEach((doc) => {
      const data = doc.data();
      const calculateTimeCompleted = () => {
        let time = data?.timeCompleted > 0 ? data?.timeCompleted : 0;
        if (data?.timeCompleted > data?.sessionLength && data?.sessionLength > 0) {
          time = data?.sessionLength;
        }
        return time;
      };
      const timeCompleted = calculateTimeCompleted();

      const dateFromTimestamp = new Date(data.timestamp);
      const weekDays = getPreviousWeekDays();
      // Check if the timestamp falls on each day
      const result = {
        monday: dateFromTimestamp >= weekDays.monday && dateFromTimestamp < weekDays.tuesday,
        tuesday: dateFromTimestamp >= weekDays.tuesday && dateFromTimestamp < weekDays.wednesday,
        wednesday: dateFromTimestamp >= weekDays.wednesday && dateFromTimestamp < weekDays.thursday,
        thursday: dateFromTimestamp >= weekDays.thursday && dateFromTimestamp < weekDays.friday,
        friday: dateFromTimestamp >= weekDays.friday && dateFromTimestamp < weekDays.saturday,
        saturday: dateFromTimestamp >= weekDays.saturday && dateFromTimestamp < weekDays.sunday,
        sunday:
          dateFromTimestamp >= weekDays.sunday &&
          dateFromTimestamp <= new Date(weekDays.sunday.setHours(23, 59, 59, 999))
      };
      if (result.monday) {
        weeklySessionsTime['monday'] = weeklySessionsTime['monday'] + timeCompleted;
      } else if (result.tuesday) {
        weeklySessionsTime['tuesday'] = weeklySessionsTime['tuesday'] + timeCompleted;
      } else if (result.wednesday) {
        weeklySessionsTime['wednesday'] = weeklySessionsTime['wednesday'] + timeCompleted;
      } else if (result.thursday) {
        weeklySessionsTime['thursday'] = weeklySessionsTime['thursday'] + timeCompleted;
      } else if (result.friday) {
        weeklySessionsTime['friday'] = weeklySessionsTime['friday'] + timeCompleted;
      } else if (result.saturday) {
        weeklySessionsTime['saturday'] = weeklySessionsTime['saturday'] + timeCompleted;
      } else if (result.sunday) {
        weeklySessionsTime['sunday'] = weeklySessionsTime['sunday'] + timeCompleted;
      }
    });

    totalFocusTime = Object.values(weeklySessionsTime).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    return totalFocusTime;
  } catch (error) {
    console.error('Error fetching documents: ', error);
  }
};

// Define a function to get the first time of the week
const getFirstTimeOfWeek = (date) => {
  const now = date ? new Date(date) : new Date();
  const firstDay = new Date(now);
  firstDay.setDate(firstDay.getDate() - ((firstDay.getDay() + 6) % 7));
  firstDay.setHours(0, 0, 0, 0);
  const time = firstDay.getTime();
  return time;
};

// Define a function to get the first time of the week
const getLastTimeOfWeek = (date) => {
  const now = new Date(date);
  const firstDay = new Date(now);
  firstDay.setDate(firstDay.getDate() - ((firstDay.getDay() + 6) % 7));
  firstDay.setHours(0, 0, 0, 0);
  const lastDay = new Date(firstDay);
  lastDay.setDate(lastDay.getDate() + 6);
  const time = lastDay.getTime();
  console.log(time);
  return time;
};

function calculateFocusTimeChange(currentWeek, previousWeek) {
  if (previousWeek === 0) {
    return 'Cannot calculate percentage change from zero.';
  }
  const percentageChange = (currentWeek - previousWeek) / previousWeek;
  return percentageChange;
}

const getPreviousWeekDays = () => {
  const week = getWeekDays();
  return {
    monday: getPreviousDate(week.monday),
    tuesday: getPreviousDate(week.tuesday),
    wednesday: getPreviousDate(week.wednesday),
    thursday: getPreviousDate(week.thursday),
    friday: getPreviousDate(week.friday),
    saturday: getPreviousDate(week.saturday),
    sunday: getPreviousDate(week.sunday)
  };
};

const getPreviousDate = (date) => {
  const previousWeekDate = new Date(date);
  previousWeekDate.setDate(date.getDate() - 7);
  return previousWeekDate;
};
