export const studyActionTypes = {
  SHOULD_SHOW_SESSION_HISTORY: 'SHOULD_SHOW_SESSION_HISTORY',
  UPDATE_LOADING_ROOM: 'UPDATE_LOADING_ROOM',
  START_LIVE_SESSION: 'START_LIVE_SESSION',
  STOP_LIVE_SESSION: 'STOP_LIVE_SESSION',
  START_PROCESSING_NOTES: 'START_PROCESSING_NOTES',
  STOP_PROCESSING_NOTES: 'STOP_PROCESSING_NOTES',
  RESET_SESSION_DATA: 'RESET_SESSION_DATA',
  ADD_QUIZ_DATA: 'ADD_QUIZ_DATA',
  UPDATE_QUIZ_DATA: 'UPDATE_QUIZ_DATA',
  SET_REVIEW_ACTIVE: 'SET_REVIEW_ACTIVE',
  SET_ACTIVE_QUESTION: 'SET_ACTIVE_QUESTION',
  SHOULD_SHOW_UPLOAD_MODAL: 'SHOULD_SHOW_UPLOAD_MODAL',
  SET_SESSION_LIST_DATA: 'SET_SESSION_LIST_DATA',
  SET_SELECTED_HISTORY: 'SET_SELECTED_HISTORY'
};

export type INoteData = {
  max_results?: number;
  title: string;
  quizTopic?: string;
  urlLink: string;
  storageUri?: string;
  noteId: string;
};

export type IQuizData = {
  question: string;
  options: string[];
  answer: string;
  explanation: string;
  id: string;
  noteId?: string;
  selectedAnswer: string;
};

export type IStudyData = {
  roomInfo: { roomName: string } | null;
  loadStudyRoom: boolean;
  studyNotes: Array<INoteData>;
  analysingNotes: boolean;
  quizData: IQuizData[];
  showSessionHistory: boolean;
  resultData: IStudyResult;
  showUploadModal: boolean;
  sessionListData: IStydySession[];
};

export type IStudyResult = {
  correctAnswersPercentage: number;
  incorrectAnswersPercentage: number;
  correctAnswersCount: number;
};

export type IStydySession = {
  docId: string;
  quizData: Array<IQuizData>;
  studyNotes: Array<INoteData>;
  sessionDate: number;
  resultData: IStudyResult;
  docId: string;
  sessionId?: string;
};
