import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { capitalize, random } from 'lodash';
import { MixlAudioPlayer } from 'mixl-audio';
import { toast } from 'react-toastify';

import endSessionAudio from '../../assets/Loaders/session_ends.mp3';
import { firebaseAuth, firebaseDB, getUserToken, recordAnalytics } from '../../components/firebase/firebase';
import { ALLOWED_DURATION_FOR_REVIEW } from '../../constants/theme';
import {
  deleteDaySchedule,
  editDaySchedule,
  fetchScheduleToday,
  generateRandomId,
  saveDaySchedule
} from '../../Services/CalendarService';
import { getAgentResponse } from '../../Services/ChatService';
import { fetchClickupTasks, fetchJiraTasks, fetchNotionTasks } from '../../Services/ClickupService';
import { getQuoteBreak, getQuoteWarmup } from '../../Services/feedService';
import Request from '../../Services/Request';
import { getFocusScore, getGoalCompletion, goalEstimations, goalStats } from '../../Services/StatsService';
import {
  getBlockingSites,
  getQueryFocusScore,
  getSiteRelevance,
  getTaskCategory,
  getTaskFocusScore,
  getTaskPriority,
  updateClickupTaskProgress
} from '../../Services/TaskService';
import { ISlackCredential, IUserSlackCredential } from '../../Types/mixlTypes';
import { isTaskToday } from '../../utils/dateUtils';
import { getFocusTabsScore, getTimeDifferenceInMins, sleep } from '../../utils/miscUtils';
import {
  calculateTotalFocusScore,
  flowStates,
  formatMusicStationName,
  mixlTabs,
  MUSIC_STATIONS,
  taskOrder
} from '../../utils/mixlFlow';
import { prioritiesList } from '../../utils/musicFeedUtils';
import {
  inIframe,
  isCapacitor,
  isDesktopApp,
  isExtenstionSidebar,
  isOffscreenIframe,
  postIframeMessage
} from '../../utils/platformUtils';
import { IMixlEvent, IMixlTask } from '../../utils/Types';
import { shouldShowMixlPlusModal, updatePlatformError } from '../device/action';
import {
  pauseMusicTrack,
  pauseMusicTrackAction,
  playMusicTrack,
  playMusicTrackAction,
  resumeMusicTrackAction
} from '../player/action';
import { playerActionTypes } from '../player/types';
import { RootState } from '../root-reducer';
import { analyzeStudyOutline, resetStudySession, updateQuizData } from '../study/action';
import { saveStudySessionData } from '../study/functions';
import { updateShowAgentView } from '../style/action';
import { createMixlTaskObject, getAiSessionData, handleNotificationsBadges, lookupUserSubscription } from './functions';
import { ISessionType, ITrainingOutline, mixlActionTypes } from './types';

export const defaultMilestoneTime = 300;
export const defaultShortMilestoneTime = 1800;

export const sessionLengthThreshold = 1800;

export const warmupTime = 10;

const { PostSecuredData } = Request();

export const startGeneratingTaskData = (taskId) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.ADD_TASK_DATA_QUEUE,
    taskId
  });
};

export const endGeneratingTaskData = (taskId) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.REMOVE_TASK_DATA_QUEUE,
    taskId
  });
};
export const updateHeartRate = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_HEART_RATE,
    value
  });
};
export const updateOxygenLevel = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_OXYGEN_LEVEL,
    value
  });
};

export const setFullProgressAfterSongIsFinished = () => {
  return async (dispatch, getState) => {
    const store: RootState = getState();
    const fullProgressValue = store?.mixlData?.fullProgressValue;
    const playerProgress = store?.feedPlayerData?.playerProgress;
    dispatch({
      type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
      payload: fullProgressValue + playerProgress
    });
  };
};

export const updateSessionType = (sessionType: ISessionType) => (dispatch) => {
  dispatch({ type: mixlActionTypes.UPDATE_SESSION_TYPE, sessionType });
};

export const fetchMusicExtenally = (song) => (dispatch, getState) => {
  const store: RootState = getState();
  const currentMixlScreen = store.mixlData.currentMixlScreen;
  const stopTimer = localStorage.getItem('stopTimer');

  const phaseBreak = currentMixlScreen === 'break';
  const showCalmPhase = phaseBreak;
  const userBlockedTime = store.mixlData.userBlockedTime;
  dispatch(addToPlayerProgress(0));
  dispatch({
    type: mixlActionTypes.SET_CURRENT_PHASE,
    payload: showCalmPhase ? flowStates.calm.phase : flowStates.focus.phase
  });
  dispatch({
    type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
    payload: 0
  });
  dispatch({
    type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
    payload: song
  });
  dispatch({
    type: mixlActionTypes.SET_CURRENT_MUSIC,
    payload: showCalmPhase ? song.calm_phase : song.focus_phase
  });

  dispatch({
    type: mixlActionTypes.FETCHING_PLAYLIST,
    payload: false
  });
  if (userBlockedTime > 0 && stopTimer !== 'true') {
    dispatch(
      playMusicTrackAction({
        content: showCalmPhase ? song.calm_phase : song.focus_phase,
        contentType: 'feed'
      })
    );
  }
  if (stopTimer !== 'true') dispatch(setShowCountdownTimer(true));
};

export const fetchingPlaylist = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.FETCHING_PLAYLIST,
    payload
  });
};

const saveMusic = async (song, currentSessionId) => {
  if (!currentSessionId) {
    return;
  }
  //save music in session data to persist current music
  const uid = firebaseAuth.currentUser?.uid;
  const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshotflowSessions = await getDoc(flowSessionsRef);
  if (snapshotflowSessions.exists()) {
    await updateDoc(flowSessionsRef, {
      currentMusic: song
    });
  }
};

export const fetchMixlMusic = (disableLoader, userInitiated, forceNotPlaying) => async (dispatch, getState) => {
  console.log('fetchMixlMusic');
  const store: RootState = getState();
  const musicStation = store.mixlData.musicStation;
  if (musicStation === 'nomusic') {
    if (isOffscreenIframe()) {
      dispatch(setShowCountdownTimer(true));
    }
    return;
  }

  const fullCurrentMusicData = store.mixlData.fullCurrentMusicData;
  const currentMusic = store.mixlData.currentMusic;
  const userBlockedTime = store.mixlData.userBlockedTime;
  const sessionStarted = store.mixlData.sessionStarted;
  const currentSessionId = store.mixlData.currentSessionId;

  const currentMixlScreen = store.mixlData.currentMixlScreen;
  let isMusicPlaying = store.feedPlayerData.isMusicPlaying;
  let musicPlaylists = store.mixlData.musicPlaylists;
  const phaseBreak = currentMixlScreen === 'break';
  const showCalmPhase = phaseBreak;
  const playlist_name = showCalmPhase
    ? 'ambient'
    : MUSIC_STATIONS.find((e) => e.name === musicStation)?.abrev || MUSIC_STATIONS[0].abrev;

  if (userInitiated && isExtenstionSidebar()) {
    isMusicPlaying = store.feedPlayerData.isMusicPlaying;
    if (isMusicPlaying && !forceNotPlaying) {
      await dispatch(pauseMusicTrack());
    }
  }
  // if timer not ended yet after refresh
  if (!disableLoader) {
    dispatch({
      type: mixlActionTypes.FETCHING_PLAYLIST,
      payload: true
    });
    if (isOffscreenIframe()) {
      postIframeMessage({ type: 'fetching_music', fetching: true });
    }
    await sleep(300);
  }

  try {
    // init music playlist
    if (!musicPlaylists) {
      musicPlaylists = await fetchPlaylistsData();
      dispatch({
        type: mixlActionTypes.SET_MUSIC_LIST,
        payload: musicPlaylists
      });
    }

    let playlist = musicPlaylists[playlist_name];
    if (playlist && fullCurrentMusicData && fullCurrentMusicData?.hasMoreSongs && fullCurrentMusicData?.timestamp) {
      const checkNewList = playlist.filter((station) => station.timestamp > fullCurrentMusicData?.timestamp);
      if (checkNewList.length) {
        playlist = checkNewList;
      }
    }
    if (playlist?.length > 0) {
      let newSongIndex = 0;
      if (!currentMusic) {
        newSongIndex = random(0, playlist?.length - 1, false);
      }
      if (!playlist.length) {
        throw new Error('No music found');
      }
      const songData = playlist[newSongIndex];
      const hasMoreSongs = playlist.length - newSongIndex > 1;
      const calm_phase = {
        ...songData.calm_phase
      };
      const focus_phase = {
        ...songData.focus_phase
      };
      const song = {
        hasMoreSongs,
        calm_phase: calm_phase,
        focus_phase: focus_phase,
        timestamp: songData.timestamp,
        id: songData.id
      };

      if (userInitiated && isExtenstionSidebar() && !forceNotPlaying) {
        console.log('userInitiated: sending refresh_music command ', song, songData);
        postIframeMessage({ type: 'refresh_music', song });
      }

      // check the phase of the milestone

      dispatch({
        type: mixlActionTypes.SET_FULL_PROGRESS_MUSIC,
        payload: 0
      });
      dispatch({
        type: mixlActionTypes.SET_CURRENT_PHASE,
        payload: showCalmPhase ? flowStates.calm.phase : flowStates.focus.phase
      });
      dispatch({
        type: mixlActionTypes.SET_CURRENT_MUSIC,
        payload: showCalmPhase ? songData.calm_phase : songData.focus_phase
      });
      dispatch({
        type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
        payload: song
      });
      dispatch({
        type: mixlActionTypes.FETCHING_PLAYLIST,
        payload: false
      });
      if (isOffscreenIframe()) {
        postIframeMessage({ type: 'fetching_music', fetching: false });
      }
      if (userBlockedTime > 0 && (userInitiated || sessionStarted) && !forceNotPlaying) {
        dispatch(
          playMusicTrack({ content: showCalmPhase ? songData.calm_phase : songData.focus_phase, contentType: 'feed' })
        );
      }

      saveMusic(song, currentSessionId);

      recordAnalytics('fetchMusicSuccess');
    } else {
      dispatch({
        type: mixlActionTypes.FETCHING_PLAYLIST,
        payload: false
      });
      toast.success('No music found', {
        className: 'slow-toast',
        autoClose: 2000
      });
    }
    const stopTimer = localStorage.getItem('stopTimer');
    if (stopTimer !== 'true') dispatch(setShowCountdownTimer(true));
  } catch (err) {
    console.log('error: ', err);
    recordAnalytics('fetchMusicError');
    dispatch({
      type: mixlActionTypes.FETCHING_PLAYLIST,
      payload: false
    });
  }
};

export const addToPlayerProgress = (value) => {
  return async (dispatch) => {
    if (isOffscreenIframe()) {
      postIframeMessage({ type: 'player_progress', progressValue: value });
    }
    dispatch({
      type: playerActionTypes.UPDATE_PLAYER_PROGRESS,
      payload: {
        playerProgress: value
      }
    });
  };
};

export const updateSessionId = (sessionId) => async (dispatch) => {
  if (inIframe()) {
    postIframeMessage({ sessionId, type: 'sessionId' });
  }
  dispatch({
    type: mixlActionTypes.UPDATE_SESSION_ID,
    sessionId
  });
};

// create goal session
export const createSession = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, breakTime, blockedSites, fullCurrentMusicData } = store.mixlData;
  // if userBlocked time is not set dont proceed
  const userBlockedTime = store.mixlData.userBlockedTime;
  if (!userBlockedTime) {
    throw new Error('invalid_session_time');
  }
  const uid = firebaseAuth.currentUser?.uid;
  const timestamp = Date.now();
  const taskName = selectedTaskClickup?.name ?? '';
  const docRef = doc(collection(firebaseDB, 'glo-users', uid, 'flow_sessions'));
  const sessionId = docRef.id;
  const goalCompletion = null;
  const payload = {
    sessionId,
    blockedSites: blockedSites ?? [],
    timestamp,
    taskName,
    sessionLength: userBlockedTime,
    timeCompleted: 0,
    goalCompletion,
    taskClickup: selectedTaskClickup?.id ?? '',
    syncCurrentTime: timestamp,
    description: '',
    breakTime,
    disableSiteBlocker: true,
    currentMusic: fullCurrentMusicData,
    ...selectedTaskClickup
  };

  await setDoc(docRef, payload);
  dispatch(updateSessionId(sessionId));
  dispatch(setUserBlockTimeGoal(taskName));

  return payload;
};

export const startFocusSession = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const { currentSessionId, selectedTaskClickup, selectedSessionType } = store.mixlData;

  if (!currentSessionId) {
    throw new Error('invalid_session_data');
  }

  const taskProgressText = 'in progress';
  dispatch(editMixlTask({ ...selectedTaskClickup, status: { status: taskProgressText } }));
  console.log('startFocusSessionDebug: ', selectedTaskClickup);
  if (selectedTaskClickup?.type === 'clickup' && selectedTaskClickup?.status?.status === 'Open') {
    const taskId = selectedTaskClickup?.id;
    updateClickupTaskProgress(taskId, taskProgressText);
  }

  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    await updateDoc(docRef, {
      startedSession: true,
      syncCurrentTime: Date.now()
    });
    dispatch({
      type: mixlActionTypes.UPDATE_SESSION_STATUS,
      payload: true
    });
  }

  const statusRef = doc(firebaseDB, 'glo-users', uid, 'sessions_status', 'currentSession');
  const sessionStatusPayload = { sessionId: currentSessionId, active: true };
  if (selectedSessionType) {
    sessionStatusPayload['sessionType'] = selectedSessionType;
  }
  await setDoc(statusRef, sessionStatusPayload);

  if (isCapacitor) {
    dispatch(setShowCountdownTimer(true));
    dispatch(fetchMixlMusic(true, true));
  }

  if (isExtenstionSidebar()) {
    // starts offscreen frame for music and timer
    postIframeMessage({ type: 'background_music', command: 'start_music' });
    // this will make toolbar visible? and also update tabs info for focus stats
    postIframeMessage({ type: 'startSession' });
    dispatch(setShowCountdownTimer(true));
  }
  if (selectedSessionType === 'work') {
    await dispatch(maybeShowFocusTip());
  }
};

export const setUserBlockTimeGoal = (value) => (dispatch) => {
  if (inIframe()) {
    postIframeMessage({ taskName: value, type: 'taskName' });
  }
  dispatch({
    type: mixlActionTypes.SET_BLOCK_TIME_GOAL,
    payload: value
  });
};

export const setShowCountdownTimer = (value) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { userBlockedTime } = store.mixlData;
  dispatch({
    type: mixlActionTypes.SET_STOP_START_TIMER,
    payload: value
  });
  if (isCapacitor) {
    if (!value) await MixlAudioPlayer.stopPermanentlyTimer();
    else await MixlAudioPlayer.startSessionTimer({ sessionLength: userBlockedTime });
  }
};

export const maybeCloseWindow = () => (dispatch, getState) => {
  const store = getState() as RootState;
  const { currentSessionId, sessionStarted } = store.mixlData;
  if (currentSessionId && sessionStarted) {
    window.CapacitorCustomPlatform.runAppInBackground();
    return;
  }
  window.CapacitorCustomPlatform.closeMixlApp();
};

export const resetBlockedSites = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });

  console.log('fetchBlockedSitesDebug: generarating new site blocker data');
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: true
  });
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: []
  });
  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: false
  });
  if (currentSessionId) {
    await updateDoc(docRef, {
      blockedSites: [],
      disableSiteBlocker: true
    });
  }
  postIframeMessage({ type: 'blocked_sites', command: 'clear_list' });
  postIframeMessage({
    type: 'block_all_sites',
    command: 'clear_list',
    allowedSites: []
  });
};

export const fetchBlockedSites = (task, sessionLength, disableSiteBlocker) => async (dispatch, getState) => {
  const store: RootState = getState();
  if (disableSiteBlocker) {
    return;
  }
  const { currentSessionId, blockedSites, blockAllSites } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: blockAllSites || false
  });

  console.log('fetchBlockedSitesDebug: blocking all sites ', currentSessionId, blockedSites, blockAllSites);
  const sitesData = [];
  const blockedSitesData = [];
  if (blockedSites.length) {
    const siteNames = blockedSites.filter((e) => e.Tag === 'Block').map((e) => e.Website);
    sitesData.push(...siteNames);
    return;
  } else {
    console.log('fetchBlockedSitesDebug: no block site found checking database');
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    blockedSitesData.push(data?.blockedSites ?? []);
    const siteNames = blockedSitesData.filter((e) => e.Tag === 'Block').map((e) => e.Website);
    sitesData.push(...siteNames);
  }

  if (sitesData.length) {
    console.log('fetchBlockedSitesDebug: setting site blocker ', sitesData);
    postIframeMessage({
      type: 'blocked_sites',
      command: 'save_list',
      blockedSitesData,
      blockedSites: sitesData
    });
    return;
  }
  console.log('fetchBlockedSitesDebug: generarating new site blocker data');
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: true
  });
  const recommendedResult = await getBlockingSites(task, sessionLength);
  const sites = recommendedResult?.sites ?? [];
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: sites
  });
  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: false
  });
  await updateDoc(docRef, {
    blockedSites: sites,
    disableSiteBlocker: false
  });
  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSitesData: [...sites],
    blockedSites: sites.filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });
  dispatch(rewardBlockSites());
};

export const updateBlockSitesCategory = (sites) => (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: sites
  });
  updateDoc(docRef, {
    blockedSites: sites
  });
  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSitesData: [...sites],
    blockedSites: sites.filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });
};

export const setEndAudioPlayed = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.PLAYED_END_SESSION_AUDIO,
    payload: payload
  });
};

export const reduceBlockTimerOneSecond = (remainingTime?: number) => async (dispatch, getState) => {
  let newValueSeconds = remainingTime;
  const store: RootState = getState();
  // prettier-ignore
  const { 
    userBlockedTime, sessionLength, currentMixlScreen, fullCurrentMusicData,
    currentPhase, musicStation, selectedTaskClickup, blockedSites, selectedSessionType,
    breakTime, showTimerCounting, playedEndSessionAudio, enableFocusTip, sessionTimeFocused
  } = store.mixlData;
  const currentSessionId = store.mixlData.currentSessionId;
  const timeSpent = sessionLength - userBlockedTime;
  if (!currentSessionId) {
    return;
  }
  dispatch(decideFocusTip(timeSpent));
  if (isCapacitor) {
    if (userBlockedTime < newValueSeconds) {
      newValueSeconds = userBlockedTime - 1;
    }
  }
  if (isNaN(remainingTime)) {
    newValueSeconds = userBlockedTime - 1;
  }

  if (newValueSeconds < 0) {
    newValueSeconds = 0;
  }

  if (sessionLength - newValueSeconds >= warmupTime && currentMixlScreen === mixlTabs.warmup) {
    dispatch(setMixlScreen(mixlTabs.initial));

    const uid = firebaseAuth.currentUser?.uid;
    const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const snapshotflowSessions = await getDoc(flowSessionsRef);
    if (snapshotflowSessions.exists()) {
      await updateDoc(flowSessionsRef, {
        notifWork: true,
        tabs: []
      });
    }
  }
  if (newValueSeconds && newValueSeconds <= breakTime) {
    console.log('debugReduceBlockTimerOneSecond: is ready for break?');
    dispatch(pauseMusicTrackAction());
    if (selectedSessionType === 'quiz') {
      dispatch(directlyEndSession());
      return;
    } else {
      dispatch(setMixlAgentView('breathing'));
    }
    if (!isExtenstionSidebar() && !playedEndSessionAudio) {
      const audio = new Audio(endSessionAudio);
      await sleep(500);
      audio.play();
      dispatch(setEndAudioPlayed(true));
    }
  }

  if (isOffscreenIframe() && newValueSeconds > 0) {
    if (showTimerCounting) {
      const phaseName = currentPhase === 'calm' ? 'calm_phase' : 'focus_phase';
      const diff = fullCurrentMusicData?.[phaseName]?.diff;
      const brainFrequency = diff ? `${diff} Hz` : '-';
      const musicStationName = capitalize(formatMusicStationName(musicStation));
      const focusScore = selectedTaskClickup?.focusScore;
      const taskName = selectedTaskClickup?.taskName ?? '';
      const blockedSiteCount = blockedSites.filter((item) => item?.Tag === 'Block').length;
      const focusStats = selectedTaskClickup?.focusStats;
      console.log('blockedSiteCount: ', blockedSiteCount, blockedSites);
      postIframeMessage({
        focusStats,
        taskName,
        remainingTime: newValueSeconds,
        breakTime,
        focusScore,
        blockedSiteCount,
        brainFrequency,
        musicStationName,
        type: 'taskTime'
      });
    }
  }

  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: newValueSeconds
  });
  if (document.hasFocus()) {
    dispatch(updateFocusedSessionTime());
  }

  const sessionId = store.mixlData.currentSessionId;
  if (newValueSeconds % 10 === 0) {
    if (selectedSessionType === 'quiz') {
      const sessionTimeSpent = sessionLength - userBlockedTime;
      const minScore = Math.floor((sessionTimeFocused / (sessionTimeSpent - 1)) * 100);
      const focusScore = Math.min(minScore, 100);
      dispatch(editMixlTask({ ...selectedTaskClickup, focusScore, sessionTimeSpent }, true));
    }
    await updateSessionTimer(sessionId, newValueSeconds).then(() => {
      dispatch(updateSessionData(sessionId));
    });
    if (isExtenstionSidebar()) {
      if (!enableFocusTip) {
        return;
      }
      const tabsInfo = [...(selectedTaskClickup?.tabs ?? [])];
      const activeTabIndex = tabsInfo.findIndex((item) => item.active === true);
      if (activeTabIndex > -1) {
        const activeTab = tabsInfo[activeTabIndex];
        const link = activeTab?.url ?? '';
        if (activeTab) {
          const siteRelevance = activeTab.siteRelevance;
          const isWhitelistedDomain = link.includes('localhost') || link.includes('mixl.ai');
          if (!isWhitelistedDomain && siteRelevance < 5) {
            const lastAccessed = activeTab.lastAccessed;
            if (Date.now() > lastAccessed + 1000 * 60 * 5) {
              if (!activeTab?.didShowNotification) {
                console.log('debugReduceBlockTimerOneSecond: sending notification here');
                const botResponseAction = {
                  text: `Looks like this site is not relevant to your task. Do you want to block it?`,
                  isBot: true,
                  shouldGlow: true,
                  type: 'focus-tip',
                  options: [
                    { text: 'Accept', selected: false, link, tabId: activeTab.id },
                    { text: 'Decline', selected: false }
                  ],
                  action: 'block_distraction'
                };
                await dispatch(
                  addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] })
                );
                activeTab['didShowNotification'] = true;
                dispatch(editMixlTask({ ...selectedTaskClickup, tabs: [...tabsInfo] }));
              }
            }
          }
        }
      }
    }
  }
};

/**
 * Keep track of time spent in the focused session.
 * @param timeSpent - a number if empty increase existing number.
 * @return {void}
 */
export const updateFocusedSessionTime = (timeSpent?: number) => (dispatch) => {
  dispatch({ type: mixlActionTypes.UPDATE_TIME_FOCUSED, timeSpent });
};

export const directlyEndSession = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { currentSessionId, selectedSessionType } = store.mixlData;
  if (currentSessionId) {
    await dispatch(endFocusSession(currentSessionId));
  }
  dispatch(setMixlAgentView('default'));
  if (selectedSessionType) {
    dispatch(updateSessionType(''));
    dispatch(resetStudySession());
  }
};

export const updateSessionData = (sessionId) => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  if (!sessionId) {
    return;
  }

  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const sessionData = snapshot.data();
    dispatch({
      type: mixlActionTypes.SET_SESSION_DATA,
      payload: sessionData
    });
  }
};

export const updatePlayedMusic = () => {
  return async (dispatch) => {
    dispatch(fetchMixlMusic(true, false));
  };
};

export const addBlockedWebsite = (siteName) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId, blockedSites } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  if (blockedSites.includes(siteName)) {
    return;
  }
  const newSite = {
    Description: siteName,
    Time: 300,
    Website: siteName,
    color: '#8DCD76',
    // icon: '/src/assets/icons/link-black-icon.svg',
    Tag: 'Block'
  };

  dispatch({
    type: mixlActionTypes.ADD_BLOCKED_SITE,
    siteName: newSite
  });
  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSitesData: [...blockedSites],
    blockedSites: [...blockedSites, newSite].filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    await updateDoc(docRef, {
      blockedSites: [
        ...blockedSites,
        {
          Description: siteName,
          Time: 5,
          Website: siteName,
          color: '#8DCD76',
          // icon: '/src/assets/icons/link-black-icon.svg',
          Tag: 'Block'
        }
      ]
    });
  }
};

export const checkMobileTimer = (id, addOffset) => async (dispatch, getState) => {
  const store: RootState = getState();

  const { currentSessionId } = store.mixlData;
  const sessionId = currentSessionId || id;
  if (!sessionId) {
    return;
  }

  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  const sessionData = snapshot.data();

  if (sessionData) {
    const stopTimer = localStorage.getItem('stopTimer') === 'true';
    const newDuration = getTimerValue(sessionData, stopTimer);
    // console.log('newDuration====', newDuration, sessionData);
    dispatch({
      type: mixlActionTypes.SET_TIMER_VALUE,
      payload: newDuration
    });
    dispatch(updateFocusedSessionTime(sessionData?.sessionTimeSpent || 0));
    dispatch({
      type: mixlActionTypes.SET_SESSION_FULL_TIME,
      payload: sessionData.sessionLength
    });
    dispatch({
      type: mixlActionTypes.SET_BREAK_TIME,
      payload: sessionData.breakTime
    });
    dispatch({
      type: mixlActionTypes.SET_SKIPPED_WARMUP,
      payload: sessionData.skippedWarmup ?? false
    });

    if (newDuration <= 0) {
      if (addOffset) {
        const reviewDuration = newDuration + ALLOWED_DURATION_FOR_REVIEW;
        if (reviewDuration > 0) {
          dispatch(shouldShowRestartSessionModal(true));

          return false;
        }
      }
      return true;
    }
  }
};
const triggerMixlChat = (message, type) => async (dispatch) => {
  const botResponse = {
    text: message,
    isBot: true,
    shouldGlow: true,
    type
  };
  const history = { role: 'model', parts: [{ text: botResponse.text }] };
  await dispatch(addChatMessage(botResponse, history));
};

export const shouldShowRestartSessionModal = (status) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_RESTART_SESSION_MODAL,
    status
  });
  if (status === true) {
    dispatch(triggerMixlChat('Let’s practice box breathing and reflect.', 'end_session'));
  }
};

export const showGlobalLoadingAlert = (isOpen) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: isOpen
  });
};

export const setBlockedList = (payload) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload
  });
};

export const clearBlockedList = () => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BLOCKED_SITES,
    payload: []
  });
  //clear blocked sites
  postIframeMessage({ type: 'blocked_sites', command: 'clear_list' });
};

export const clearBlockAllSites = () => async (dispatch) => {
  postIframeMessage({
    type: 'block_all_sites',
    command: 'clear_list',
    allowedSites: []
  });

  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: false
  });
};

export const saveTimeCompleted = (sessionId) => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const { userBlockedTime, selectedTaskClickup } = store.mixlData;
  const id = store.mixlData.currentSessionId;
  const currentSessionId = id || sessionId;

  // update active status of the session
  if (!currentSessionId) {
    return;
  }
  const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshotflowSessions = await getDoc(flowSessionsRef);
  if (snapshotflowSessions.exists()) {
    const sessionData = snapshotflowSessions.data();
    const sessionLength = sessionData?.sessionLength;
    const timeCompleted = sessionLength - userBlockedTime;
    const session = selectedTaskClickup || (await getSession(currentSessionId));
    const taskId = session.id;
    const taskData = await getTaskData(taskId);

    if (session) {
      await dispatch(
        editMixlTask({
          ...taskData,
          timeCompleted: timeCompleted + (taskData?.timeCompleted || 0)
        })
      );
    }
    // time completed for that session only
    await updateDoc(flowSessionsRef, {
      startedSession: false,
      timeCompleted: timeCompleted || 0
    });
  }
};

const getTaskData = async (taskId) => {
  const uid = firebaseAuth.currentUser?.uid;
  let taskData = {};
  if (!uid) return;
  const collectionRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');

  const queryCal = query(collectionRef, where('id', '==', taskId));
  const querySnapshot = await getDocs(queryCal);
  if (!querySnapshot.empty) {
    // Get the document reference for the existing event
    taskData = querySnapshot?.docs?.[0]?.data();
  }

  return taskData;
};

export const increaseUnreadMessageCount = () => (dispatch, getState) => {
  const store: RootState = getState();
  const unreadMessages = store.mixlData.unreadMessages;
  dispatch({
    type: mixlActionTypes.UPDATE_UNREAD_MESSAGE_COUNT,
    count: unreadMessages + 1
  });
};

export const clearUnreadMessageCount = () => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_UNREAD_MESSAGE_COUNT,
    count: 0
  });
};

export const endFocusSession = (sessionId) => async (dispatch, getState) => {
  const store: RootState = getState();
  const uid = firebaseAuth.currentUser?.uid;
  const id = store.mixlData.currentSessionId;
  const currentSessionId = id || sessionId;
  console.log('endFocusSession: ', currentSessionId);
  if (isCapacitor) {
    await MixlAudioPlayer.stopPermanentlyTimer();
  }
  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'start_break_session' });
  }

  dispatch(saveTimeCompleted(currentSessionId));

  //clear blocked sites after session has ended
  dispatch(resetBlockedSites());
  dispatch(setTimestampStartTimer(Date.now()));
  dispatch(setShowCountdownTimer(true));
  postIframeMessage({ stopTimer: false, type: 'stopTimer' });
  localStorage.setItem('stopTimer', 'false');

  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: true
  });

  // update active status of the session
  if (!currentSessionId) {
    return;
  }

  const payload = {
    active: false
  };
  const docRef = doc(firebaseDB, 'glo-users', uid, 'sessions_status', 'currentSession');
  const statusSnapshot = await getDoc(docRef);
  if (statusSnapshot.exists()) {
    await updateDoc(docRef, payload);
  }

  dispatch(setMixlScreen(mixlTabs.initial));

  dispatch(updateSessionId(null));
  dispatch(setUserBlockTimeGoal(''));
  if (isCapacitor) {
    await MixlAudioPlayer.stopSessionTimer();
  }
  dispatch(pauseMusicTrackAction());
  const { selectedSessionType } = store.mixlData;

  if (selectedSessionType === 'study') {
    dispatch(setSelectedTrainingOutline(null));
    dispatch(loadTrainingOutlines(null));
    if (store.studyData.studyNotes.length) {
      saveStudySessionData(store.studyData, currentSessionId);
    }
  }
  const skipFirstMessage = selectedSessionType || selectedSessionType !== 'work';

  dispatch(clearChatHistory(skipFirstMessage));
  dispatch(setChatLoading(false));

  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'background_music', command: 'stop_music' });
    postIframeMessage({
      type: 'end_session',
      badge: '',
      station: ''
    });
  }

  dispatch({
    type: mixlActionTypes.UPDATE_SESSION_STATUS,
    payload: false
  });

  // finish loading
  dispatch({
    type: mixlActionTypes.SET_END_SESSION_LOADING,
    payload: false
  });

  const newStations = await handleNotificationsBadges();
  dispatch(updateStations(newStations));
  //update ai cards data
  const aiSessionsData = await getAiSessionData();
  dispatch(setAiSessionsData(aiSessionsData));

  localStorage.removeItem('stopTimer');
  dispatch(updateSessionData(null));
  // clear timer if paused
  dispatch(setShowCountdownTimer(true));
  dispatch(setSelectedTaskClickup(null));
  dispatch(setMixlAgentView('default'));
  dispatch(updateShowAgentView(true));
  if (isOffscreenIframe()) {
    postIframeMessage({ type: 'end_session' });
    postIframeMessage({ type: 'background_music', command: 'stop_music' });
  }
};

export const setBlockWebsite = (url) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockedSites, currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  const updated_sites = blockedSites.map((site) => (site.Website === url ? { ...site, Tag: 'Block' } : { ...site }));
  dispatch(setBlockedList(updated_sites));
  await updateDoc(docRef, {
    blockedSites: blockedSites
  });
};

export const toggleBlockAllSites = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockAllSites, blockedSites, currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  await updateDoc(docRef, {
    blockAllSites: !blockAllSites
  });
  console.log('blockAllSites====', blockAllSites);
  if (blockAllSites === false) {
    postIframeMessage({
      type: 'block_all_sites',
      command: 'save_list',
      allowedSites: blockedSites.filter((e) => e.Tag === 'Allow').map((e) => e.Website)
    });
  } else {
    postIframeMessage({
      type: 'block_all_sites',
      command: 'clear_list',
      allowedSites: blockedSites.filter((e) => e.Tag === 'Allow').map((e) => e.Website)
    });
  }
  dispatch({
    type: mixlActionTypes.SET_BLOCK_ALL_SITES,
    payload: !blockAllSites
  });
};

export const handleSelectionSite = (url) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { blockedSites, currentSessionId } = store.mixlData;

  const updated_sites = blockedSites.map((site) =>
    site.Website === url ? { ...site, Tag: site.Tag === 'Allow' ? 'Block' : 'Allow' } : { ...site }
  );
  dispatch(setBlockedList(updated_sites));

  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);

  postIframeMessage({
    type: 'blocked_sites',
    command: 'save_list',
    blockedSitesData: updated_sites,
    blockedSites: updated_sites.filter((e) => e.Tag === 'Block').map((e) => e.Website)
  });

  await updateDoc(docRef, {
    blockedSites: updated_sites
  });
};

export const SyncSessionData = (snapshot) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId, currentMixlScreen } = store.mixlData;
  const { isMusicPlaying } = store.feedPlayerData;
  if (currentSessionId) {
    return;
  }

  if (!snapshot.exists()) {
    console.log('Document not found');
    return;
  }
  const sessionData = await snapshot.data();
  const session = await getSession(sessionData.sessionId);
  if (!session) return;
  const stopTimer = localStorage.getItem('stopTimer');
  if (sessionData?.active) {
    if (!session?.notifBreak) {
      if (!currentMixlScreen) dispatch(setMixlScreen(mixlTabs.focus));
    }
    const shouldEnd = await dispatch(checkMobileTimer(session?.sessionId, true));
    if (shouldEnd) {
      console.log('SyncSessionData: we should end session here');
      dispatch(endFocusSession(session?.sessionId, shouldEnd));
      dispatch(saveTimeCompleted(session?.sessionId));
      return;
    }

    dispatch(setMixlAgentActive(true));
    dispatch(updateShowAgentView(true));
    if (isCapacitor && stopTimer !== 'true') {
      await MixlAudioPlayer.startSessionTimer({ sessionLength: session?.sessionLength });
    }
    const isSidebar = isExtenstionSidebar();
    if (isSidebar && stopTimer !== 'true') {
      postIframeMessage({ type: 'background_music', command: 'start_music' });
      dispatch(resumeMusicTrackAction());
    }
    dispatch(updateSessionId(session?.sessionId));
    dispatch(setUserBlockTimeGoal(session?.taskName));
    if (session?.blockedSites?.length) {
      const blockedSites = session?.blockedSites ?? [];
      if (blockedSites?.length) {
        const siteNames = blockedSites.filter((e) => e.Tag === 'Block').map((e) => e.Website);
        postIframeMessage({
          type: 'blocked_sites',
          command: 'save_list',
          blockedSitesData: blockedSites,
          blockedSites: siteNames
        });
      }
      dispatch({
        type: mixlActionTypes.SET_BLOCKED_SITES,
        payload: blockedSites
      });
      dispatch({
        type: mixlActionTypes.SET_BLOCK_ALL_SITES,
        payload: session?.blockAllSites
      });
    }

    // !! DO NOT TOUCH !!
    if (!isMusicPlaying) {
      dispatch(fetchMixlMusic(true, !isSidebar));
    } else {
      dispatch(setShowCountdownTimer(true));
      dispatch({
        type: mixlActionTypes.SET_CURRENT_MUSIC,
        payload: session?.notifBreak ? session?.currentMusic?.calm_phase : session?.currentMusic?.focus_phase
      });
      dispatch({
        type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
        payload: session?.currentMusic
      });
    }
    const taskClickup = session?.taskClickup;
    if (taskClickup) {
      const uid = firebaseAuth.currentUser?.uid;
      if (!uid) return;
      const clickupTaskRef = doc(firebaseDB, 'glo-users', uid, 'mixl_events', taskClickup.toString());
      const taskData = await getDoc(clickupTaskRef)
        .then((taskSnapshot) => {
          if (!taskSnapshot.exists) {
            return {};
          }
          const data = taskSnapshot.data();
          if (data) {
            return { ...data, id: taskClickup };
          }
          return { ...session, id: taskClickup };
        })
        .catch((err) => {
          console.log('SyncSessionDataerr: ', err);
          return {};
        });

      dispatch(setSelectedTaskClickup({ id: taskClickup, ...taskData }));
    }
    dispatch({
      type: mixlActionTypes.UPDATE_SESSION_STATUS,
      payload: session.startedSession
    });
    console.log('SyncSessionData: ', session);
    const sessionType = sessionData?.sessionType;
    if (sessionType) {
      dispatch(updateSessionType(sessionType));
      if (sessionType === 'study') {
        const studySessionOutline = session?.studySessionOutline;
        if (studySessionOutline) {
          dispatch(loadTrainingOutlines(studySessionOutline));
          if (studySessionOutline?.length) {
            dispatch(setSelectedTrainingOutline(studySessionOutline[0]));
          }
        }
        const studyNotes = session?.studyNotes;
        if (studyNotes) {
          dispatch(updateQuizData([], studyNotes));
        }
      }
    }
  }
};

export const setShowAppUpdateModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_APP_UPDATE_MODAL,
    payload
  });
};

export const setSelectedTaskClickup = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
    payload
  });
};

export const setIsScheduling = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.IS_SCHEDULING,
    payload
  });
};

export const setShowSiteBlockerModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_SITE_BLOCKER_MODAL,
    payload
  });
};

export const setAddSiteBlockModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_ADD_BLOCK_SITE_MODAL,
    payload
  });
};

export const updateSessionTaskData = async (sessionId, data) => {
  const uid = firebaseAuth.currentUser?.uid;
  if (!sessionId) {
    return;
  }
  const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshotflowSessions = await getDoc(flowSessionsRef);
  if (snapshotflowSessions.exists()) {
    await updateDoc(flowSessionsRef, { ...data });
  }
};

export const editMixlTask = (task: IMixlTask, updateSession: boolean) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { clickupTasks, selectedTaskClickup, dailyGoals, sessionStarted, currentSessionId } = store.mixlData;
  const docId = task.id;
  if (!docId) {
    return;
  }

  const updatedTask = { ...task };

  editDaySchedule(updatedTask);
  if (dailyGoals.length > 0) {
    const updatedGoals = [...dailyGoals].map((item) => {
      if (item.id === task.id) {
        return updatedTask;
      }
      return item;
    });
    if (sessionStarted && currentSessionId && updateSession) {
      await updateSessionTaskData(currentSessionId, updatedTask);
    }
    dispatch(updateDailyGoals(updatedGoals));
  }

  console.log('Document successfully edited!');
  const newTasks = [...clickupTasks].filter((item) => item.id !== docId);
  newTasks.push({ ...updatedTask });
  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload: [...newTasks]
  });

  if (docId === selectedTaskClickup?.id) {
    dispatch({
      type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
      payload: { ...updatedTask }
    });
  }
  const showTaskDetailsModal = getState()?.mixlData?.showTaskDetailsModal;
  if (showTaskDetailsModal) {
    dispatch(setShowTaskDetailsModal(updatedTask));
  }
  return updatedTask;
};

export const addMixlTask =
  (taskName: string, withPriority = true) =>
  async (dispatch, getState) => {
    const store: RootState = getState();
    const { dailyGoals, selectedSessionType } = store.mixlData;
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(collection(firebaseDB, 'glo-users', uid, 'mixl_events'));
    const docId = docRef.id;
    const timeNow = Date.now();
    const newTask: IMixlTask = createMixlTaskObject(taskName, docId, timeNow);
    if (withPriority) {
      const priorityResponse = await getTaskPriority({ ...newTask });
      const taskPriority = priorityResponse.priority;
      newTask.priority.priority = taskPriority;
      newTask.priority.orderIndex = taskOrder[taskPriority];
    }
    if (!isExtenstionSidebar()) {
      switch (selectedSessionType) {
        case 'quiz':
          newTask.focusScore = 80;
          break;
        case 'study':
          newTask.focusScore = 75;
          break;
        default:
          newTask.focusScore = 75;
          break;
      }
    }
    dispatch(setChatLoading(true));
    await setDoc(docRef, { ...newTask });
    const newDailyGoals = [{ ...newTask }];
    if (dailyGoals.length > 0) {
      newDailyGoals.push(...dailyGoals);
    }
    dispatch(updateDailyGoals([...newDailyGoals]));
    setChatLoading(false);
    return newTask;
  };

export const addQuickMixlTask =
  (taskName: string, otherData = {}) =>
  async (dispatch, getState) => {
    const store: RootState = getState();
    const { clickupTasks } = store.mixlData;
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(collection(firebaseDB, 'glo-users', uid, 'mixl_events'));
    const timeNow = Date.now();
    let newTask: IMixlTask = {
      details: '',
      status: {
        status: 'open'
      },
      name: taskName ?? '',
      isTodayTask: true,
      type: 'custom',
      tagName: '',
      priority: {
        color: '#6fddff',
        id: '3',
        orderindex: '3',
        priority: 'normal'
      },
      createdAt: timeNow,
      id: docRef.id,
      startTimestamp: timeNow,
      hasAnimated: false,
      ...otherData
    };

    dispatch({
      type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
      payload: [...clickupTasks, { ...newTask }]
    });
    dispatch(startGeneratingTaskData(newTask.id));
    await setDoc(docRef, { ...newTask })
      .then(async () => {
        const autogenData = await autogenTaskData(newTask);
        if (autogenData) {
          const { priorityType, goalScore, ...rest } = autogenData;
          if (priorityType !== newTask.priority.priority) {
            const priorityItem = prioritiesList.find((item) => item.priority === priorityType);
            if (priorityItem) {
              newTask.priority = { ...priorityItem };
            }
          }
          const focusStats = newTask?.focusStats || {};
          focusStats['goalScore'] = goalScore;
          const focusScore = calculateTotalFocusScore(focusStats);

          newTask = dispatch(editMixlTask({ ...newTask, focusStats, focusScore, ...rest }));
          dispatch(setShowGlowingScore(true));
          setTimeout(() => {
            dispatch(setShowGlowingScore(false));
          }, 4000);
        }
      })
      .finally(() => {
        dispatch({
          type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
          payload: { ...newTask }
        });
        dispatch(endGeneratingTaskData(newTask.id));
      });

    return newTask;
  };

export const updateClickupTaskList = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload
  });
};

export const deleteMixlTask = (task: IMixlTask) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { clickupTasks, selectedTaskClickup } = store.mixlData;
  const docId = task.id;
  if (!docId) {
    return;
  }
  dispatch(setShowTaskDetailsModal(null));

  dispatch({
    type: mixlActionTypes.SET_CLICKUP_LIST_TASKS,
    payload: [...clickupTasks].filter((e) => e.id !== task.id)
  });
  if (task.id === selectedTaskClickup?.id) {
    dispatch({
      type: mixlActionTypes.SET_SELECTED_TASK_CLICKUP,
      payload: null
    });
  }
  await deleteDaySchedule(task);

  const uid = firebaseAuth.currentUser?.uid;
  const eventList = await fetchScheduleToday(uid);
  await dispatch(updateDailyGoals([...eventList]));
};

export const fetchingTaskList = (isLoading) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.LOADING_CLICKUP_LIST_TASKS,
    payload: isLoading
  });
};

export const handleSelectedStation = (payload) => async (dispatch) => {
  if (payload.noMusic) {
    dispatch(pauseMusicTrackAction());
    dispatch({
      type: mixlActionTypes.UPDATE_MUSIC_STATION,
      payload: 'nomusic'
    });
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid);
    const snapshot = await getDoc(docRef);
    //  const userData = snapshot.data();
    if (snapshot.exists()) {
      await updateDoc(docRef, { musicStation: 'nomusic' });
    }
    return;
  }

  dispatch({
    type: mixlActionTypes.UPDATE_MUSIC_STATION,
    payload: payload.name
  });
  dispatch(fetchMixlMusic(payload.disableLoader, true));
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid);
  const snapshot = await getDoc(docRef);
  //  const userData = snapshot.data();
  if (snapshot.exists()) {
    await updateDoc(docRef, { musicStation: payload.name });
  } else {
    await setDoc(docRef, { musicStation: payload.name });
  }
};

export const setSelectedStation = (payload) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MUSIC_STATION,
    payload
  });
};

export const updatePlayerVolume = (payload) => async (dispatch) => {
  if (isExtenstionSidebar()) {
    postIframeMessage({ type: 'adjust_volume', playerVolume: payload });
  }
  dispatch({
    type: mixlActionTypes.UPDATE_MAIN_VOLUME,
    payload
  });
  localStorage.setItem('playerVolume', payload);
  if (isCapacitor) {
    await MixlAudioPlayer.updateVolume({ volume: parseFloat(payload) });
  }
};

export const setFocusStatsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_FOCUS_STATS_MODAL,
    payload
  });
};

export const setShowStationsListModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_STATIONS_LIST_MODAL,
    payload
  });
};

export const setShowUpdVolumeModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_UPDATE_VOLUME_MODAL,
    payload
  });
};

export const setShowTaskDetailsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SHOW_TASK_DETAILS_MODAL,
    payload
  });
};

// todo: are we using it?
export const setSessionFullDuration = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SESSION_FULL_TIME,
    payload: data
  });
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: data
  });
};

export const allowPublishSlack = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.PUBLISH_TO_SLACK,
    payload: data
  });
};

export const updateLikesSong = (song, liked) => async (dispatch, getState) => {
  const store: RootState = getState();
  const musicStation = store.mixlData.musicStation;
  const playlist_name = MUSIC_STATIONS.find((e) => e.name === musicStation)?.abrev;
  const docRef = doc(firebaseDB, 'ai_music', 'place_v13', `${playlist_name}`, `${song.id}`);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const data = snapshot.data();
    const currentLikes = data?.likesList || [];
    const uid = firebaseAuth.currentUser?.uid;
    const index = currentLikes.findIndex((e) => e.uid === uid);
    if (index > -1) {
      currentLikes[index].liked = liked;
    } else {
      currentLikes.push({ uid, liked: liked });
    }
    await updateDoc(docRef, {
      likesList: currentLikes
    });
    dispatch({
      type: mixlActionTypes.SET_FULL_CURRENT_MUSIC,
      payload: { ...song, likesList: currentLikes }
    });
  }
};

export const setBreakTime = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_BREAK_TIME,
    payload: data
  });
};

export const setSelectedOptionTimer = (data) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_TIMER_OPTION,
    payload: data
  });
  dispatch({
    type: mixlActionTypes.SET_SESSION_FULL_TIME,
    payload: data.workTime + data.breakTime
  });
  dispatch({
    type: mixlActionTypes.SET_TIMER_VALUE,
    payload: data.workTime + data.breakTime
  });
  dispatch({
    type: mixlActionTypes.SET_BREAK_TIME,
    payload: data.breakTime
  });
};

export const publishSlackPost = (selectedTaskClickup: IMixlTask) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { publishToSlack } = store.mixlData;
  const slackCredential: ISlackCredential = store.businessData.slackCredential;
  if (slackCredential && publishToSlack) {
    const slackToken = slackCredential?.authed_user?.access_token ?? '';
    const scope: string = slackCredential?.authed_user?.scope ?? '';
    if (slackToken && scope.includes('chat:write')) {
      const slackChannels = slackCredential.slackChannels;
      const selectedChannel = slackChannels?.find((item) => item.isSelected);
      if (selectedChannel && selectedChannel.channelId) {
        const slackChannelId = selectedChannel?.channelId;
        // todo: refactor this
        const taskStatus =
          selectedTaskClickup?.status?.status === 'done'
            ? 'Hey, quick update. This task is done: '
            : 'Hey, quick update. This task is in progress: ';
        const taskName = selectedTaskClickup?.name ?? '';
        const taskId = selectedTaskClickup?.id ?? '';
        const messageText = `${taskStatus}\n\n${taskName}. \n\nI’m using Mixl for focus and automatic updates.`;
        const token = await getUserToken();
        await PostSecuredData(
          'community/publish_slack',
          {
            slackToken,
            slackChannelId,
            messageText,
            taskId
          },
          `Bearer ${token}`
        );
      }
    }
  }
};

export const sendSlackMessage =
  (messageText: string, userSlackCredential: IUserSlackCredential, timeLeft: number) => async (dispatch, getState) => {
    const store: RootState = getState();
    const slackCredential: ISlackCredential = store.businessData.slackCredential;

    if (slackCredential && userSlackCredential && messageText) {
      const slackToken = slackCredential?.authed_user?.access_token ?? '';
      const scope: string = slackCredential?.authed_user?.scope ?? '';
      const slackUserId = userSlackCredential?.id ?? '';
      if (slackToken && scope.includes('chat:write') && slackUserId) {
        const token = await getUserToken();
        if (timeLeft <= 0) {
          //post now
          await PostSecuredData(
            'community/send_slack_dm',
            {
              slackToken,
              slackUserId,
              messageText
            },
            `Bearer ${token}`
          );
        } else {
          // Get the current Unix timestamp in seconds
          const currentTimestamp = Math.floor(Date.now() / 1000);

          // Add timeleft seconds to the current timestamp
          const postTimestamp = currentTimestamp + timeLeft;
          console.log('postTimestamp', postTimestamp);
          await PostSecuredData(
            'community/schedule_slack_dm',
            {
              slackToken,
              slackUserId,
              messageText,
              post_at: postTimestamp
            },
            `Bearer ${token}`
          );
        }
      }
    }
  };

export const selectBlockSites = () => async (dispatch) => {
  const botResponseAction = {
    text: `Select or deselect sites, or add your own to keep your focus sharp.`,
    isBot: true,
    action: 'select_block_sites',
    clickAction: 'select_block_sites'
  };
  await dispatch(addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] }));
};

export const searchandEarn = () => async (dispatch) => {
  const botResponse = {
    text: 'I can help you get info fast and avoid online distractions. Ask any time during your session',
    isBot: true
  };
  await dispatch(addChatMessage(botResponse, { role: 'model', parts: [{ text: botResponse.text }] }));
};
export const updateUserGoalInSession = (userGoal) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, chatHistory, enableFocusTip } = store.mixlData;
  if (!enableFocusTip) {
    return;
  }
  const currentGoalScore = selectedTaskClickup?.focusStats?.goalScore ?? 0;
  const totalBand = 25;
  const benchMark = totalBand * 0.5;
  if (currentGoalScore >= benchMark) {
    return;
  }

  const potentialIncreaseScore = totalBand - currentGoalScore;
  dispatch(setChatLoading(true));

  const { chat_response } = await getAgentResponse(
    selectedTaskClickup?.name || userGoal,
    chatHistory,
    'suggest_goal',
    !!selectedTaskClickup
  );

  const message_thread = [
    {
      text: `Update your goal to a SMART goal`,
      isBot: true,
      shouldGlow: true,
      clickAction: 'update_goal',
      type: 'focus-tip',
      value: `+${potentialIncreaseScore}`
    },
    {
      text: `SMART = Specific, Measurable, Achievable, Relevant, and Time-Bound.`,
      isBot: true,
      clickAction: 'update_goal'
    },
    {
      text: `Here is an example: ${chat_response}`,
      isBot: true,
      clickAction: 'update_goal'
    }
  ];

  const botResponseAction = {
    ...message_thread[0],
    message_thread
  };
  await dispatch(addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] }));

  dispatch(setChatLoading(false));
};

export const BlockDistractingSites = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, enableFocusTip } = store.mixlData;
  if (!enableFocusTip) {
    return;
  }
  const currentGoalScore = selectedTaskClickup?.focusStats?.siteRelevance ?? 0;
  const totalBand = 25;
  const benchMark = totalBand * 0.5;
  if (currentGoalScore >= benchMark) {
    return;
  }
  const triggeredActions = selectedTaskClickup?.triggeredActions || [];
  if (triggeredActions.includes('block_sites')) {
    return;
  }
  dispatch(setChatLoading(true));
  await dispatch(
    editMixlTask({
      ...selectedTaskClickup,
      triggeredActions: [...triggeredActions, 'block_sites']
    })
  );
  const message_thread = [
    {
      text: `Block distracting sites based on your goal.`,
      isBot: true,
      shouldGlow: true,
      clickAction: 'block_sites',
      type: 'focus-tip',
      value: `+5`
    },
    {
      text: `Distracting ads and sites may be hurting your focus. Let’s boost your focus score  by intelligently blocking ads and sites unrelated to your goal.`,
      isBot: true,
      clickAction: 'block_sites'
    },
    {
      text: `Say “yes” to accept`,
      isBot: true,
      clickAction: 'block_sites'
    }
  ];

  const botResponseAction = {
    ...message_thread[0],
    message_thread
  };
  await dispatch(addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] }));

  dispatch(setChatLoading(false));
};

export const CloseInactiveTabs = (tabsScore) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, enableFocusTip } = store.mixlData;
  if (!enableFocusTip) {
    return;
  }
  const currentGoalScore = tabsScore || (selectedTaskClickup?.focusStats?.tabsScore ?? 0);
  const totalBand = 25;
  const benchMark = totalBand * 0.5;
  if (currentGoalScore >= benchMark) {
    return;
  }
  const triggeredActions = selectedTaskClickup?.triggeredActions || [];
  if (triggeredActions.includes('close_tabs')) {
    return;
  }
  dispatch(setChatLoading(true));

  const message_thread = [
    {
      text: `Close inactive tabs.`,
      isBot: true,
      shouldGlow: true,
      clickAction: 'close_tabs',
      type: 'focus-tip',
      value: `+10`
    },
    {
      text: `Having too many open tabs hurts focus. Do you want me to close all inactive tabs now to improve your focus score? You can also close tabs manually.`,
      isBot: true,
      clickAction: 'close_tabs'
    },
    {
      text: `Say “yes” to close all inactive tabs.`,
      isBot: true,
      clickAction: 'close_tabs'
    }
  ];

  const botResponseAction = {
    ...message_thread[0],
    message_thread
  };
  await dispatch(addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] }));
  await dispatch(
    editMixlTask({
      ...selectedTaskClickup,
      triggeredActions: [...triggeredActions, 'close_tabs']
    })
  );
  dispatch(setChatLoading(false));
};

export const Research = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, enableFocusTip } = store.mixlData;
  if (!enableFocusTip) {
    return;
  }
  const currentGoalScore = selectedTaskClickup?.focusStats?.queryScore ?? 0;
  const totalBand = 25;
  const benchMark = totalBand * 0.5;
  if (currentGoalScore >= benchMark) {
    return;
  }
  const triggeredActions = selectedTaskClickup?.triggeredActions || [];
  if (triggeredActions.includes('research')) {
    return;
  }
  dispatch(setChatLoading(true));

  const botResponseAction = {
    text: `Use Mixl for research`,
    isBot: true,
    shouldGlow: true,
    clickAction: 'research',
    type: 'focus-tip',
    value: `+10`
  };
  await dispatch(addChatMessage(botResponseAction, { role: 'model', parts: [{ text: botResponseAction.text }] }));
  await dispatch(
    editMixlTask({
      ...selectedTaskClickup,
      triggeredActions: [...triggeredActions, 'research']
    })
  );
  dispatch(setChatLoading(false));
};

export const combineGoalMessages = (clickActionString) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { chatMessages: messages } = store.mixlData;
  const updateGoalMessages = messages.filter((msg) => msg.clickAction === clickActionString);
  const otherMessages = messages.filter((msg) => msg.clickAction !== clickActionString);

  // Create a new collapsed message for 'update_goal' messages
  const collapsedMessage = {
    ...updateGoalMessages[0],
    messages: updateGoalMessages.slice(1).map((msg) => ({ ...msg }))
  };

  // Combine collapsed 'update_goal' message with other messages
  const finalMessages = [...otherMessages, collapsedMessage];
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_messages', 'productivityAgent');
  const messagesData = await getDoc(docRef).then((messagesSnapshot) => {
    if (!messagesSnapshot.exists) {
      return {};
    }
    const data = messagesSnapshot.data();
    if (data) {
      return data;
    }
    return {};
  });
  const updatedMessages = { ...messagesData, messages: finalMessages };
  await setDoc(docRef, updatedMessages);
  await dispatch(updateActiveMessages(finalMessages));
};

export const triggerAutogenTaskData = (newTask: IMixlTask) => async (dispatch, getState) => {
  console.log('triggerAutogenTaskData: starting gen ', newTask);
  const store = getState() as RootState;
  const { selectedSessionType } = store.mixlData;
  if (selectedSessionType !== 'work') {
    return newTask;
  }
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: true
  });
  const autogenData = await autogenTaskData(newTask);
  console.log('triggerAutogenTaskData: autogenData ', autogenData);
  if (!autogenData) {
    dispatch({
      type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
      payload: false
    });

    return newTask;
  }
  const { priorityType, goalScore, ...rest } = autogenData;
  if (priorityType !== newTask.priority.priority) {
    const priorityItem = prioritiesList.find((item) => item.priority === priorityType);
    if (priorityItem) {
      newTask.priority = { ...priorityItem };
    }
  }
  const focusStats = newTask?.focusStats || {};
  focusStats['goalScore'] = goalScore;
  const focusScore = calculateTotalFocusScore(focusStats);
  const result = { ...newTask, focusStats, focusScore, ...rest };
  dispatch(editMixlTask(result));
  dispatch(setShowGlowingScore(true));
  setTimeout(() => {
    dispatch(setShowGlowingScore(false));
  }, 4000);
  dispatch({
    type: mixlActionTypes.SET_GENERATING_BLOCKED_SITES,
    payload: false
  });

  return result;
};

export const autogenTaskData = async (newTask: IMixlTask) => {
  const taskName = newTask.name;
  const taskPayload = { taskName };
  const genRequests = [getTaskPriority(taskPayload), getTaskCategory(taskPayload), getTaskFocusScore(taskPayload)];

  const autogenData = await Promise.allSettled(genRequests).then((results) => {
    const taskData = {
      details: '',
      tagName: '',
      priorityType: 'normal',
      sites: [],
      goalScore: 0
    };
    for (const result of results) {
      if (result.status === 'fulfilled') {
        const resultType = result.value.type;
        switch (resultType) {
          case 'description': {
            const descriptionResult: string = result.value?.description ?? '';
            if (descriptionResult) {
              taskData.details = descriptionResult.replaceAll('\n ', '\n');
            }
            break;
          }
          case 'tagName': {
            const tagResult = result.value?.tagName;
            if (tagResult) {
              taskData.tagName = tagResult;
            }
            break;
          }
          case 'priority': {
            const priorityValue = result.value?.priority ?? '';
            if (priorityValue) {
              taskData.priorityType = priorityValue;
            }
            break;
          }
          case 'blockedSites': {
            const sitesData = result.value?.sites ?? [];
            if (sitesData?.length > 0) {
              const sites = sitesData.map((item) => {
                if (item?.icon) {
                  delete item.icon;
                }
                return item;
              });
              taskData.sites = [...sites];
            }
            break;
          }
          case 'goalScore': {
            const goalScore = result.value?.goalScore;
            console.log('goalScore: from api ', goalScore);
            if (goalScore) {
              const scoreInPercentage = Number(goalScore * 2.5);
              console.log('goalScore: scoreInPercentage ', scoreInPercentage);

              if (scoreInPercentage) {
                taskData.goalScore = Math.round(scoreInPercentage);
              } else {
                taskData.goalScore = 5;
              }
            } else {
              taskData.goalScore = 5;
            }

            break;
          }
        }
      }
    }
    return taskData;
  });

  return autogenData;
};

// Deprecated: Please remove
export const loadTaskListFromDatabase = () => async (dispatch) => {
  const eventList: IMixlEvent[] = [];
  try {
    dispatch(setIsScheduling(true));
    const uid = firebaseAuth.currentUser?.uid;
    const totalEvents = await fetchScheduleToday(uid);
    console.log('loadTaskListFromDatabase: totalEvents ', totalEvents);
    if (totalEvents && totalEvents.length > 0) {
      const eventsToday = await removeYesterdaysTasks(totalEvents);
      console.log('loadTaskListFromDatabase: eventsToday ', eventsToday);
      if (eventsToday.length > 0) {
        eventList.push(...eventsToday);
      }
    }
    let pmTasks = [];
    let filteredExistingTasks = [];

    pmTasks = await dispatch(fetchPmTasks());

    if (pmTasks.length > 0) {
      filteredExistingTasks = pmTasks.filter((task) => {
        return !eventList.some((event) => event.id === task.id);
      });
      await saveDaySchedule(filteredExistingTasks);
    }

    dispatch(updateDailyGoals([...eventList, ...filteredExistingTasks]));
  } catch (error) {
    console.log('Err: loadTaskListFromDatabase ', error);
  } finally {
    dispatch(setIsScheduling(false));
  }
  return [...eventList];
};

export const fetchPmTasks = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { clickupCredential, jiraCredential, notionCredential } = store.businessData;
  try {
    const fetchTaskRequests = [];
    const fireToken = await getUserToken();

    if (clickupCredential) {
      fetchTaskRequests.push(fetchClickupTasks(fireToken));
    }

    if (jiraCredential) {
      fetchTaskRequests.push(fetchJiraTasks(fireToken));
    }
    if (notionCredential) {
      fetchTaskRequests.push(fetchNotionTasks(fireToken));
    }

    const totalTasks = await Promise.allSettled(fetchTaskRequests).then((results) => {
      return results
        .filter((item) => item.status === 'fulfilled')
        .map((result) => result.value)
        .flat();
    });

    return totalTasks;
  } catch (error) {
    console.log('Err: fetchPmTasks ', error);
    return [];
  }
};

const removeYesterdaysTasks = async (events: IMixlEvent[]) => {
  const remainingEvents = await Promise.all(
    events.map(async (event) => {
      const isTodayTask = isTaskToday(new Date(event.createdAt));
      if (!isTodayTask) {
        await deleteDaySchedule(event);
        return null;
      } else {
        return event;
      }
    })
  );
  return remainingEvents.filter((event) => event !== null);
};

export const updateMixlCalendarEvents = (events: Array<IMixlEvent>) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MIXL_CALENDAR,
    events
  });
};

const maybeShowFocusTip = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const selectedTaskClickup = store.mixlData.selectedTaskClickup;
  if (selectedTaskClickup?.focusScore >= 50) {
    return;
  }
  await sleep(2000);
  dispatch(updateUserGoalInSession(selectedTaskClickup?.name));
};

const decideFocusTip = (timeSpent) => async (dispatch) => {
  // for users with low stats in first 3 mins
  if (timeSpent < 240) {
    if (timeSpent === 60) {
      dispatch(BlockDistractingSites());
      return;
    }
    if (timeSpent === 120) {
      dispatch(CloseInactiveTabs());
      return;
    }
    if (timeSpent === 180) {
      dispatch(Research());
      return;
    }
  } else {
    // for users with low stats or inactive tabs after 3 mins
    dispatch(CloseInactiveTabs());
  }
};

async function updateSessionTimer(sessionId: string) {
  if (!sessionId) {
    return;
  }
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const sessionData = snapshot.data();
    const sessionLength = sessionData?.sessionLength;
    // check for negative values. e.g unhandled case of timer counting after session has ended
    const timeUsed = getTimerValue(sessionData);
    const timeCompleted = timeUsed <= sessionLength ? sessionLength - timeUsed : sessionLength;
    const breakTime = sessionData?.breakTime;

    await updateDoc(docRef, { timeCompleted });
    if (
      sessionLength - breakTime > 0 &&
      timeCompleted > (sessionLength - breakTime) / 2 &&
      !sessionData.notifHalfaway
    ) {
      const uid = firebaseAuth.currentUser?.uid;
      const flowSessionsRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
      const snapshotflowSessions = await getDoc(flowSessionsRef);
      if (snapshotflowSessions.exists()) {
        await updateDoc(flowSessionsRef, {
          notifHalfaway: true
        });
      }
    }
  }
}

export const setMixlScreen = (payload) => (dispatch) => {
  if (payload === 'break') {
    const quote = getQuoteBreak();
    dispatch({
      type: mixlActionTypes.SET_SESSION_BREAK_QUOTE,
      payload: quote
    });
  } else if (payload === 'warmup') {
    const quote = getQuoteWarmup();
    dispatch({
      type: mixlActionTypes.SET_SESSION_WARMUP_QUOTE,
      payload: quote
    });
    dispatch(handleUpdateSkipWarmup(false));
  }
  dispatch({
    type: mixlActionTypes.SET_MIXL_SCREEN,
    payload
  });
};

// todo: are we using it?
export const handleUpdateSkipWarmup = (skip) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  dispatch({
    type: mixlActionTypes.SET_SKIPPED_WARMUP,
    payload: skip
  });
  // localStorage.setItem('skipwarmup', skip);
  if (!currentSessionId) {
    return;
  }
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  const snapshot = await getDoc(docRef);
  const sessionData = snapshot.data();

  if (sessionData) {
    await updateDoc(docRef, {
      skippedWarmup: skip
    });
  }
};

// todo: are we using it?
const getSession = async (sessionId) => {
  try {
    let lastSession = null;
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
    const querySnapshot = await getDoc(docRef);
    if (!querySnapshot.exists()) {
      return null;
    }

    lastSession = querySnapshot.data();
    return lastSession;
  } catch (error) {
    console.error('Error getting documents: ', error);
  }
};

// todo: are we using it?
const fetchPlaylistsData = async () => {
  const playlists = {};
  for (const station of MUSIC_STATIONS) {
    const dbRef = collection(firebaseDB, 'ai_music', 'place_v13', `${station.abrev}`);
    const refQuery = query(dbRef, orderBy('timestamp', 'asc'));
    const snapshot = await getDocs(refQuery);
    if (snapshot.size > 0) {
      playlists[station.abrev] = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }
  }
  //this is just temporary because we may have updated the playlist it will take time to generate
  if (!playlists[MUSIC_STATIONS[0].abrev]) {
    const dbRef = collection(firebaseDB, 'ai_music', 'place_v11', 'playlist');
    const refQuery = query(dbRef, orderBy('timestamp', 'asc'));
    const snapshot = await getDocs(refQuery);
    if (snapshot.size > 0) {
      playlists[MUSIC_STATIONS[0].abrev] = snapshot.docs.map((doc) => doc.data());
    }
  }
  return playlists;
};

export const setMuted = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_MAIN_VOLUME,
    payload: value
  });
};

export const updateCurrentVolume = (value) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_PLAYER_VOLUME,
    payload: value
  });
};

export const handleStartSession = () => async (dispatch, getState) => {
  //start session
  const store = getState() as RootState;
  const { currentSessionId } = store.mixlData;
  let newSessionId;
  if (currentSessionId) {
    newSessionId = currentSessionId;
  } else {
    const { sessionId } = await dispatch(createSession());
    newSessionId = sessionId;
  }
  dispatch(setMixlScreen(mixlTabs.focus));
  await dispatch(startFocusSession());
  dispatch(setEndAudioPlayed(false));
  if (isExtenstionSidebar()) {
    postIframeMessage({
      type: 'get_opened_tabs'
    });
  }
  return newSessionId;
};

export const setAiSessionsData = (payload) => {
  return {
    type: mixlActionTypes.SET_AI_SESSIONS_DATA,
    payload
  };
};

export const updateStations = (payload) => {
  return {
    type: mixlActionTypes.SET_MUSIC_STATIONS,
    payload
  };
};

export const setTimestampStartTimer = (payload) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { currentSessionId } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
  await updateDoc(docRef, {
    startedTimeAt: payload
  });
};

const getTimerValue = (sessionData, stopTimer) => {
  // console.log('sessionDatasessionData', sessionData, stopTimer);
  if (stopTimer) {
    const remainingTime = sessionData?.remainingTime;
    return remainingTime;
  } else {
    if (sessionData?.startedTimeAt && sessionData.remainingTime) {
      const syncCurrentTime = sessionData.startedTimeAt; //timestamp
      const currentTime = Date.now();
      const currentDuration = sessionData.remainingTime;
      const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const newDuration = currentDuration - differenceInSeconds;
      return newDuration;
    }
    if (sessionData?.notifBreak && sessionData?.breakStartTime) {
      const syncCurrentTime = sessionData.breakStartTime;
      const currentTime = Date.now();
      const currentDuration = sessionData.breakTime;
      const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const newDuration = currentDuration - differenceInSeconds;
      return newDuration;
    }

    const syncCurrentTime = sessionData.syncCurrentTime; //timestamp
    const currentTime = Date.now();
    const currentDuration = sessionData.sessionLength;
    const differenceInMilliseconds = Math.abs(currentTime - syncCurrentTime);
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const newDuration = currentDuration - differenceInSeconds;
    return newDuration;
  }
};

export const setshowWorkHoursModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_WORK_HOURS_MODAL,
    payload
  });
};

export const setshowFocusDetailsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_FOCUS_DETAILS_MODAL,
    payload
  });
};

export const getUserSessionData = (userEmail) => async (dispatch, getState) => {
  const token = await getUserToken();
  const { user } = await PostSecuredData('authapi/get_user_session', { userEmail }, token);
  const store = getState() as RootState;
  const selectedUser = store.mixlData?.selectedUser;
  if (selectedUser && selectedUser.email === userEmail) {
    const userData = { ...selectedUser, ...user };
    if (userData) {
      dispatch({
        type: mixlActionTypes.UPDATE_SELECTED_USER,
        payload: userData
      });
    }
  }
};

export const setSelectedUser = (userEmail) => async (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_USER,
    payload: userEmail
  });
};

export const setShowIntegrationsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_INTEGRATIONS_MODAL,
    payload
  });
};

export const setShowSlackMessageModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_SLACK_MESSAGE_MODAL,
    payload
  });
};

export const closeAllInactiveTabs = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, currentSessionId } = store.mixlData;
  if (selectedTaskClickup && currentSessionId) {
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const task = { ...selectedTaskClickup };
    const tabs = task?.tabs ?? [];
    const activeTabs = await Promise.all(
      tabs.map((tab) => {
        console.log('tab====', tab);
        if (!tab.active) {
          postIframeMessage({ type: 'close_tab', tabId: tab.id });
          dispatch(updateSessionClosedTabs(tab));
        } else {
          return tab;
        }
      })
    );
    const totalTabs = activeTabs?.length;
    const tabsScore = getFocusTabsScore(totalTabs, totalTabs);
    const focusStats = task?.focusStats || {};
    focusStats['tabsScore'] = tabsScore;
    const focusScore = calculateTotalFocusScore(focusStats);
    await updateDoc(docRef, {
      ...task,
      tabs: [...activeTabs],
      focusStats,
      focusScore
    });
    await dispatch(
      editMixlTask({
        ...task,
        tabs: [...activeTabs],
        focusStats,
        focusScore
      })
    );
  }
};

export const closeIrrelevantTabs = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, currentSessionId } = store.mixlData;
  if (selectedTaskClickup && currentSessionId) {
    const tabs = selectedTaskClickup?.tabs ?? [];
    await Promise.all(
      tabs.map((tab) => {
        const siteRelevance = tab.siteRelevance;
        console.log('siteRelevance====', tab);
        if (siteRelevance < 12) {
          postIframeMessage({ type: 'close_tab', tabId: tab.id });
          // dispatch(updateSessionClosedTabs(tab));
        } else {
          return tab;
        }
      })
    );
  }
};

export const rewardBlockSites = () => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, currentSessionId } = store.mixlData;
  if (selectedTaskClickup && currentSessionId) {
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const task = { ...selectedTaskClickup };
    const focusStats = task?.focusStats || {};
    focusStats['blockedSitesScore'] = 5;
    const focusScore = calculateTotalFocusScore(focusStats);
    await updateDoc(docRef, {
      focusStats,
      focusScore
    });
    await dispatch(
      editMixlTask({
        ...task,
        focusScore,
        focusStats
      })
    );
  }
};

export const updateSessionClosedTabs = (tabInfo) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, currentSessionId } = store.mixlData;
  // console.log('updateSessionClosedTabs: ', selectedTaskClickup, currentSessionId);
  if (selectedTaskClickup && currentSessionId) {
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const task = { ...selectedTaskClickup };

    let updatedTabs = [];
    const prevTabs = task?.tabs ?? [];
    const findTab = prevTabs.find((tabItem) => tabItem.id === tabInfo.id);
    if (findTab) {
      updatedTabs = prevTabs.filter((tabItem) => tabItem.id !== findTab.id);
      const activeTabs = getActiveBrowserTabs(updatedTabs);
      const totalTabs = updatedTabs?.length;
      const tabsScore = getFocusTabsScore(totalTabs, activeTabs);
      const focusStats = task?.focusStats || {};
      focusStats['tabsScore'] = tabsScore;
      const focusScore = calculateTotalFocusScore(focusStats);

      await updateDoc(docRef, {
        tabs: [...updatedTabs],
        focusStats,
        focusScore
      });
      await dispatch(
        editMixlTask({
          ...task,
          tabs: [...updatedTabs],
          focusStats,
          focusScore
        })
      );

      dispatch(setShowGlowingScore(true));
      setTimeout(() => {
        dispatch(setShowGlowingScore(false));
      }, 4000);
    }
  }
};

export const getActiveBrowserTabs = (tabs: Array<unknown>) => {
  if (!tabs || !tabs.length) {
    return 0;
  }
  const tabsWithActiveTime = tabs
    .map((tab) => {
      const lastAccessed = tab?.lastAccessed ?? 0;
      const lastActive = getTimeDifferenceInMins(lastAccessed);
      return { ...tab, lastActive };
    })
    .filter((tab) => tab.lastActive <= 5);

  return tabsWithActiveTime.length;
};

export const updateBrowserTabsInfo = (tabs) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup, currentSessionId } = store.mixlData;
  // console.log('updateBrowserTabsInfo: ', selectedTaskClickup, currentSessionId, tabs);
  if (selectedTaskClickup && currentSessionId) {
    const uid = firebaseAuth.currentUser?.uid;
    const docRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', currentSessionId);
    const task = { ...selectedTaskClickup };
    const activeTabs = getActiveBrowserTabs(tabs);
    const totalTabs = tabs?.length;
    const tabsScore = getFocusTabsScore(totalTabs, activeTabs);
    const focusStats = task?.focusStats || {};
    focusStats['tabsScore'] = tabsScore;
    let notifiedTabs = selectedTaskClickup?.notifiedTabs || [];
    const oldTabsInfo = selectedTaskClickup?.tabs || [];
    const goal = selectedTaskClickup.name;
    const updatedTabs = await Promise.all(
      tabs.map(async (tab) => {
        let tabItem;
        const isExistingTab = oldTabsInfo.find((item) => item.id === tab.id);
        if (isExistingTab) {
          tabItem = { ...isExistingTab, ...tab };
        } else {
          tabItem = { ...tab };
        }
        if (tabItem.active || !tabItem?.siteRelevance) {
          const site_url = tabItem?.url ?? '';
          const site_title = tabItem?.title ?? '';
          const siteRelevance = await getSiteRelevance({ goal, site_url, site_title })
            .then((data) => {
              return Number(data.siteRelevance);
            })
            .catch((err) => {
              console.log('err: ', err);
              return 0;
            });
          const isWhitelistedDomain = site_url.includes('localhost') || site_url.includes('mixl.ai');
          if (
            siteRelevance &&
            siteRelevance < 5 &&
            tabItem.active &&
            !isWhitelistedDomain &&
            !notifiedTabs.includes(tab.id)
          ) {
            postIframeMessage({ type: 'block_page_consent', tabId: tab.id });
            notifiedTabs = [...notifiedTabs, tab.id];
          }
          tabItem['siteRelevance'] = Math.round(siteRelevance);
        }
        console.log('tabItem: siteRelevance ', tabItem.siteRelevance, tabItem);
        return tabItem;
      })
    );
    console.log('updatedTabs ', updatedTabs);
    const totalTabsCount = updatedTabs.length;
    const relevantSitesCount = updatedTabs.filter((item) => item?.siteRelevance > 12).length;
    const siteRelevance = (relevantSitesCount / totalTabsCount) * 20;
    focusStats['siteRelevance'] = Math.round(siteRelevance || 0);
    const focusScore = calculateTotalFocusScore(focusStats);

    await updateDoc(docRef, {
      tabs: [...updatedTabs],
      focusStats,
      focusScore,
      notifiedTabs
    });
    await dispatch(
      editMixlTask({
        ...task,
        focusScore,
        focusStats,
        tabs: [...updatedTabs],
        notifiedTabs
      })
    );
    dispatch(setShowGlowingScore(true));
    setTimeout(() => {
      dispatch(setShowGlowingScore(false));
    }, 4000);
  }
};

export const setShowTemplateModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_TEMPLATE_MODAL,
    payload
  });
};

export const updateDailyGoals = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_DAILY_GOALS,
    payload
  });
};

export const updateActiveMessages = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_ACTIVE_MESSAGES,
    payload
  });
};
export const updateChatHistory = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.UPDATE_CHAT_HISTORY,
    payload
  });
};

export const LoadDashboardStats = () => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  const eventList = await fetchScheduleToday(uid);
  const todaySessions = await getTodaysSessions();
  dispatch(updateDailyGoals([...eventList]));
  const goalEstimationData = await goalEstimations(eventList);
  const goalStatsData = await goalStats(todaySessions);
  const goalFocusStats = await getFocusScore(eventList);
  const goalCompletionStats = await getGoalCompletion(eventList);
  return { goalEstimationData, goalStatsData, goalFocusStats, goalCompletionStats };
};

export const getTodaysSessions = async () => {
  const uid = firebaseAuth.currentUser?.uid;
  if (!uid) return [];

  // Reference the collection for user's sessions
  const sessionsRef = collection(firebaseDB, 'glo-users', uid, 'flow_sessions');
  const snapshot = await getDocs(sessionsRef);

  // Filter only sessions that have a start timestamp of today
  const todaySessions = snapshot.docs
    .map((doc) => {
      const sessionData = doc.data();
      const sessionStart = sessionData.startedTimeAt;
      const isTodayTask = isTaskToday(new Date(sessionStart));
      return isTodayTask ? sessionData : null;
    })
    .filter((session) => session !== null); // Remove null values

  return todaySessions;
};

export const mixlFirstMessage = () => async (dispatch) => {
  console.log('mixlFirstMessage: sending first message');
  const botResponse = {
    text: `Ask me research questions below, or type ‘/’ to see tools. `,
    isBot: true,
    shouldGlow: true
  };

  await dispatch(addChatMessage(botResponse, { role: 'model', parts: [{ text: botResponse.text }] }));
};

export const mixlFirstStudyMessage = (taskTime: number) => async (dispatch) => {
  const botResponse = {
    text: `Ask me questions to learn faster.`,
    botAction: `Setting a ${taskTime - 10}m focus timer.`,
    isBot: true,
    shouldGlow: true
  };

  await dispatch(addChatMessage(botResponse, { role: 'model', parts: [{ text: botResponse.text }] }));
  dispatch(setChatLoading(false));
};

export const clearChatHistory = (skipFirstMessage) => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_messages', 'productivityAgent');
  await updateDoc(docRef, {
    active: false,
    messages: [],
    history: []
  });
  await dispatch(updateChatHistory([]));
  await dispatch(updateActiveMessages([]));
  await dispatch(setUserChatActionRequired(false));
  if (!skipFirstMessage) {
    if (!isDesktopApp) {
      await sleep(2000);
      await dispatch(mixlFirstMessage());
    }
  }
};

export const addChatMessage = (chatMessage, history) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { endSessionLoading } = store.mixlData;
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_messages', 'productivityAgent');
  if (endSessionLoading) {
    return;
  }
  let message = chatMessage;
  if (chatMessage) {
    message = { ...chatMessage, id: generateRandomId() };
  }

  const messagesData = await getDoc(docRef).then((messagesSnapshot) => {
    if (!messagesSnapshot.exists) {
      return {};
    }
    const data = messagesSnapshot.data();
    if (data) {
      return data;
    }
    return {};
  });
  const timeNow = Date.now();
  let updatedMessages = {
    active: true,
    messages: []
  };

  if (message) {
    const mixlMessages = messagesData?.messages ?? [];
    if (!mixlMessages.length) {
      updatedMessages = {
        ...updatedMessages,
        createdAt: timeNow,
        messages: [message],
        history: messagesData?.history ?? []
      };
    } else {
      updatedMessages = {
        ...updatedMessages,
        updatedAt: timeNow,
        createdAt: messagesData.createdAt,
        messages: [message, ...mixlMessages],
        history: messagesData?.history ?? []
      };
    }
  } else {
    const mixlMessages = messagesData?.messages ?? [];
    const isTodayData = messagesData?.createdAt ? isTaskToday(new Date(messagesData?.createdAt)) : true;
    if (!isTodayData) {
      updatedMessages = {
        createdAt: timeNow,
        messages: []
      };
    } else {
      if (mixlMessages.length) {
        updatedMessages = {
          ...updatedMessages,
          updatedAt: timeNow,
          createdAt: messagesData.createdAt,
          messages: [...mixlMessages].map((message) => {
            return { ...message, disabled: true };
          }),
          history: messagesData?.history ?? []
        };
      }
    }
  }
  if (history) {
    const mixlChatHistory = messagesData?.history ?? [];
    if (!mixlChatHistory.length) {
      updatedMessages = {
        ...updatedMessages,
        history: [history]
      };
    } else {
      updatedMessages = {
        ...updatedMessages,
        history: [...mixlChatHistory, history]
      };
    }
  }
  await setDoc(docRef, updatedMessages);
  console.log('addChatMessage: updateChatHistory', message);
  await dispatch(updateChatHistory(updatedMessages?.history || []));
  await dispatch(updateActiveMessages(updatedMessages?.messages));
  return updatedMessages;
};

export const updateChatResponse = (messageId, option) => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_messages', 'productivityAgent');
  const messagesData = await getDoc(docRef).then((messagesSnapshot) => {
    if (!messagesSnapshot.exists) {
      return {};
    }
    const data = messagesSnapshot.data();
    if (data) {
      return data;
    }
    return {};
  });
  const mixlMessages = messagesData?.messages ?? [];
  let updatedMessages = [...mixlMessages];
  if (mixlMessages?.length > 0) {
    const findMessage = mixlMessages.find((message) => message.id === messageId);
    if (findMessage) {
      const findMessageOptions = findMessage.options.map((messageOption) => {
        if (messageOption.text === option.text) {
          return { ...messageOption, selected: true };
        }
        return { ...messageOption, selected: false };
      });
      updatedMessages = mixlMessages.map((message) => {
        if (message.id === messageId) {
          return { ...message, options: findMessageOptions };
        }
        return { ...message };
      });
    }
    await updateDoc(docRef, {
      updatedAt: Date.now(),
      messages: updatedMessages
    });
  }

  await dispatch(updateActiveMessages(updatedMessages));
};

export const updateChatThread = (messageId, newMessage) => async (dispatch) => {
  if (!messageId || !newMessage) {
    return;
  }
  const uid = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_messages', 'productivityAgent');
  const messagesData = await getDoc(docRef).then((messagesSnapshot) => {
    if (!messagesSnapshot.exists) {
      return {};
    }
    const data = messagesSnapshot.data();
    if (data) {
      return data;
    }
    return {};
  });
  const mixlMessages = messagesData?.messages ?? [];
  let updatedMessages = [...mixlMessages];
  if (mixlMessages?.length > 0) {
    const findMessage = mixlMessages.find((message) => message.id === messageId);
    if (findMessage) {
      updatedMessages = mixlMessages.map((message) => {
        if (message.id === messageId) {
          return { ...message, message_thread: [...message.message_thread, newMessage] };
        }
        return { ...message };
      });
    }
    await updateDoc(docRef, {
      updatedAt: Date.now(),
      messages: updatedMessages
    });
  }
  await dispatch(updateActiveMessages(updatedMessages));
};

export const handleCreateTask = (taskName) => async (dispatch) => {
  const task = await dispatch(
    addQuickMixlTask(taskName, {
      eventName: 'Flow'
    })
  );

  await dispatch(setSelectedTaskClickup(task));
  await dispatch(setSessionFullDuration(Number(60) * 60));
  await dispatch(setBreakTime(Number(10) * 60));
  const newTask = await dispatch(triggerAutogenTaskData(task, true));
  return newTask;
};

export const handlePrepareTask = (task: IMixlTask, sessionTime?: number) => async (dispatch) => {
  const durationInMinutes = sessionTime || 60;
  dispatch(setSelectedTaskClickup(task));
  dispatch(setSessionFullDuration(durationInMinutes * 60));
  dispatch(updateFocusedSessionTime(1));
  await dispatch(setBreakTime(Number(10) * 60));
  const newTask = await dispatch(triggerAutogenTaskData(task, true));
  return newTask;
};

export const setShowGlowingScore = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_GLOWING_SCORE,
    payload
  });
};

export const onTabWindowFocused = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const sessionStarted = store.mixlData.sessionStarted;
  if (!sessionStarted) {
    return;
  }
  const uid = firebaseAuth.currentUser?.uid;
  if (!uid) {
    return;
  }
  const docRef = doc(firebaseDB, 'glo-users', uid, 'sessions_status', 'currentSession');
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists) {
    return;
  }
  console.log('TabFocusDetector: running sync ');
  const sessionData = snapshot.data();
  const sessionId = sessionData.sessionId;
  const sessionRef = doc(firebaseDB, 'glo-users', uid, 'flow_sessions', sessionId);
  const sessionSnapshot = await getDoc(sessionRef);
  if (!sessionSnapshot.exists()) {
    return;
  }
  await dispatch(checkMobileTimer(sessionId));
};
export const setChatLoading = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_CHAT_LOADING,
    payload
  });
};

export const setUserChatActionRequired = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_USER_CHAT_ACTION_REQUIRED,
    payload
  });
};

export const setSelectedMenu = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_MENU,
    payload
  });
};
export const setMixlAgentActive = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_MIXL_AGENT_ACTIVE,
    payload
  });
};

export const updateQueryScore = (query) => async (dispatch, getState) => {
  const store: RootState = getState();
  const { selectedTaskClickup } = store.mixlData;
  const focusStats = selectedTaskClickup?.focusStats || {};
  const scoreResponse = await getQueryFocusScore(selectedTaskClickup?.name, query);
  const score = Number(scoreResponse?.score) || 0;
  const totalQueriesCount = (selectedTaskClickup?.totalQueriesCount ?? 0) + 1;
  let relevantQueriesCount = selectedTaskClickup?.relevantQueriesCount ?? 0;
  if (score > 5) {
    relevantQueriesCount = relevantQueriesCount + 1;
  }
  const queryScore = (relevantQueriesCount / totalQueriesCount) * 25;
  focusStats['queryScore'] = Math.round(queryScore);
  const focusScore = calculateTotalFocusScore(focusStats);
  const taskPayload = { ...selectedTaskClickup, focusStats, focusScore, relevantQueriesCount, totalQueriesCount };
  await dispatch(editMixlTask(taskPayload));
  dispatch(setShowGlowingScore(true));
  setTimeout(() => {
    dispatch(setShowGlowingScore(false));
  }, 4000);
};

export const setMixlAgentView = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_MIXL_AGENT_VIEW,
    payload
  });
};
export const setShowCommandsModal = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_COMMANDS_MODAL,
    payload
  });
};

export const setMixlSidePanelView = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_MIXL_SIDE_PANEL_VIEW,
    payload
  });
};

export const setShowSessionLoadingAnimation = (payload) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SHOW_SESSION_LOADING_ANIMATION,
    payload
  });
};

export const loadTrainingOutlines = (courseOutline?: Array<ITrainingOutline>) => (dispatch) => {
  const payload = courseOutline || [];
  dispatch({
    type: mixlActionTypes.UPDATE_TRAINING_OUTLINE,
    payload
  });
};

export const setSelectedTrainingOutline = (payload?: ITrainingOutline) => (dispatch) => {
  dispatch({
    type: mixlActionTypes.SET_SELECTED_TRAINING_OUTLINE,
    payload
  });
  if (payload) {
    dispatch(analyzeStudyOutline(payload));
  }
};

export const startQuickSession = (goalName, sessionType?: ISessionType) => async (dispatch, getState) => {
  const store = getState() as RootState;
  const userType = store.businessData.isPremiumUser;

  if (!userType || userType === 'free') {
    // Note: try to migrate user here
    const isPaidUser = await lookupUserSubscription();
    console.log('isPaidUser: ', isPaidUser);
    if (!isPaidUser) {
      dispatch(shouldShowMixlPlusModal(true));
      return;
    }
  }

  dispatch(setShowSessionLoadingAnimation(true));
  try {
    let taskTime = 35;
    if (sessionType) {
      if (sessionType === 'quiz') {
        taskTime = 20;
      }
      dispatch(updateSessionType(sessionType));
    }
    if (sessionType === 'work') {
      dispatch(mixlFirstStudyMessage(taskTime));
    }

    const task = await dispatch(addMixlTask(goalName, false));
    await dispatch(handlePrepareTask(task, taskTime));
    const sessionId = await dispatch(handleStartSession());
    return sessionId;
  } catch (error) {
    console.log('err: unable to create session');
    dispatch(updatePlatformError('Something went wrong. Please try again later.'));
  } finally {
    dispatch(setShowSessionLoadingAnimation(false));
  }
};
