import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import logo from '../../assets/Loaders/logo-mixlearn.png';

interface ILogoLoader {
  containerStyle: CSSProperties;
}
/**
 * displays a loader
 * @component
 * @param {string} width
 * @param {string} height
 * @param {string} text
 * @returns <LogoLoader {...{props}} />
 */
const LogoLoader = ({ containerStyle }: ILogoLoader) => {
  const { safeAreaInsets } = useSelector((state) => state.styleData);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          paddingTop: safeAreaInsets.top,
          paddingBottom: safeAreaInsets.bottom,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'black',
          ...containerStyle
        }}>
        <img style={{ width: '40%' }} src={logo} alt="loading-logo" />
      </div>
    </div>
  );
};
export default LogoLoader;
